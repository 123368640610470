.NavigationItem {
    box-sizing: border-box;
    width: 100%;
    height:3.1rem;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-self:stretch;
    overflow: hidden;
}
.NavigationLink {
    padding: 1rem;
    padding-left: 2rem;
    color: var(--link-color);
    text-decoration: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: all 300ms ease;
    transition-property: color, background, border;
    border-left: 3px solid transparent;
}
.NavigationLink:hover{
    /* color: var(--link-hover-color); */
    background-color: var(--link-hover-bg-color);
    border-left: 3px solid var(--link-hover-color);
}
.NavigationLink:active, .active{
    /* color: var(--link-hover-color); */
    border-left: 3px solid var(--link-hover-color);
    background-color:  var(--link-hover-bg-color);
}
.Active {
    /* color: var(--link-hover-color); */
    background-color:  var(--link-hover-bg-color);
    border-left: 3px solid var(--link-hover-color);
}
.ChildActive, .NavigationLink.ChildActive:hover {
    /* color: var(--info-color) !important; */
    /* color: rgb(116, 158, 255) !important; */
    font-weight: 700;
}