.Input {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    resize: none;
}


/* with icon */

.LoginInputContainer {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.LoginIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    flex-grow: 0;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    /* background-color: red; */
    z-index: 1;
}
.LoginIconContainer svg {
    padding-right: 0;
    color: var(--placeholder-color);
}
.LoginIconContainer i {
    width: 20px;
    height: 20px;
    text-align: center;
    color: var(--placeholder-color);
}