.BookmarkedTenderSearch {
    height: 60vh;
    overflow: auto;
}
.Item {
    margin: 0.7rem;
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.12);
    cursor: pointer;
    transition: border 150ms linear;
    color: var(--text-color);
    background-color: var(--background-color);
    border: 1px solid transparent;
    border-radius: 10px;
}
.Item:hover {
    /* box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.3); */
    border: 1px solid #2ea0fd;
}
.Label {
    max-width: 70%;
    overflow: hidden;
}

.Label::first-letter {
    text-transform: capitalize;
}
.Icon {
    transition: all 150ms linear;
    cursor: pointer;
    padding: 5px;
}
.Icon:hover{
    color: #fd2e2e;
}
.Icon svg {
    padding: 0;
}
.NoItems {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
}
.NoItems svg {
    color: #2ea0fd;
    margin-right: 5px;
}
@media (min-width: 480px) {
    .Item {
        padding: 1.4rem;
        margin: 1.4rem;
    }
}