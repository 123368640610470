.Notification {
    background-color: var(--notification-bg-color);
    color:var(--notification-text-color);
    border: var(--notification-border);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1006;
    transition: all 500ms ease;
    transition-property: opacity, transform;
    margin-bottom: 10px;
    opacity: 0;
    transform: translateY(150%);
    pointer-events: none;
    max-width: 80%;
    min-width: 200px;
    cursor: pointer;
    box-sizing: border-box;
}
.Show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
}
.Notification svg {
    margin-right: 5px;
}
.Notification.Success svg{
    color: #2efd50b4;
}
.Notification.Danger svg{
    color: #fd2e2e;
}
.Notification.Info svg{
    color: #2ea0fd;
}

@media (max-width:600px) {
	.Notification {
        width: 100%;
        max-width: 100%;
        right: 0;
        border-radius: 0;
    }
}