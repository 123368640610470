.ConfirmAction h2 {
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
    color: var(--title-color);
}
.Buttons {
    padding: 1.3rem;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.Buttons button {
    margin-top: 1.3rem;
}