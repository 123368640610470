.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box; 
    position: relative;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #141c281f rgba(255, 255, 255, 1);
    font-size: 15px;
    height: 100%;
}
.NavigationItems svg, 
.NavigationItems span,
.NavigationItems i {
    padding-right: 15px !important;
}
.GroupTitle {
    padding:30px;
    padding-bottom: 10px;
    color: var(--link-hover-color);
    font-size: 14px;
}
.SubmenuContainer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    color: rgb(124, 124, 124);
    color: red;
    transition: all 150ms ease-out;
    transition-property: color, background, border;
    border-left: 3px solid transparent;
    cursor: pointer;
}
.SubmenuContainer:hover {
    /* color: var(--link-hover-color); */
    background-color: var(--link-hover-bg-color);
    border-left: 3px solid var(--link-hover-color);
}
.SubmenuContainer.Active {
    /* color: var(--link-hover-color); */
    background-color: var(--link-hover-bg-color);
    border-left: 3px solid var(--link-hover-color);
}
/* .SubmenuContainer.Active .ParentLink,
.SubmenuContainer.Active .Submenu li a{
    color: var(--link-hover-color);
} */

.Submenu {
    padding: 0;
    margin: 0;
	max-height: 0;
    pointer-events: none;
	opacity: 0;
	line-height: 5px;
	transition: all 300ms ease-out;
    transition-property: padding, opacity, max-height;
}
.Submenu button {
    display: block;
    width: 100%;
}

.Submenu.Expand {
	/* padding: 0 0 18px 0; */
    padding-bottom: 20px;
	opacity: 1;
	max-height: 200px;
	pointer-events: all;
}

.Submenu li{
    /* transition: all 150ms ease-out; */
    height: fit-content;
}
.Submenu li a {
    padding: 15px 0;
    padding-left: 60px;
    width: 100%;
    height: 100%;
    display: block;
    transition: all 150ms ease-out;
    transition-property: color, transform, background, border;
}

.Submenu li a:hover{
    /* color: var(--link-hover-color); */
    transform: translateX(5px);
    background-color: #2a40e800 !important;
    border-left: 3px solid #2a40e800 !important;
}

.ParentLink {
    position:relative;
    padding: 15px;
    padding-left: 30px;
    color: var(--link-color);
    text-decoration: none;
    width: 100%;
    height: 50px;
    /* height: 50px; */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: color 150ms ease-out;

    -webkit-touch-callout : none;     /* iOS Safari */
    -webkit-user-select   : none;     /* Chrome/Safari/Opera */
    -khtml-user-select    : none;     /* Konqueror */
    -moz-user-select      : none;     /* Firefox */
    -ms-user-select       : none; 
}

.ParentLink:hover {
    /* color: var(--link-hover-color); */
}
.ParentLink::after {
    font-family: "Material-Icons";
    content: '▾';
    font-size: 15px;
    transition: all 150ms ease-out;
    transition-property: color, background;
    line-height: 10px;
    background-color: var(--submenu-arrow-bg-color);
    color: var(--submenu-arrow-color);
    display: inline-block;
    padding: 3px;
    border-radius: 3px;
    margin-left: 7px;
    text-indent: 0;
    position: absolute;
    right: 35px;
}
.SubmenuContainer:hover .ParentLink::after,
.SubmenuContainer.Active .ParentLink::after {
    background-color: var(--submenu-arrow-hover-bg-color);
    color: var(--submenu-arrow-hover-color)
}