.MyProfile {
    width: 100%;
    display: inline-block;
}
.Header {
    display: flex;
    border-bottom: var(--border-separator);
    padding: 20px 0;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
}
.Avatar {
    padding: 0 1.3rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}
.Avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid rgba(150, 150, 150, 0.37);
    border: var(--border-separator);
    object-fit: cover;
}
.Username {
    max-width: 200px;
    overflow: hidden;
    box-sizing: border-box;
    margin-right: 1.3rem;
}
.Links {
    padding: 1.3rem 0;
}
.MyProfile a,
.Links>div {
    padding: 0.7rem 1.3rem;
    color: var(--link-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: color 150ms ease-in;
}
.Links>div {
    padding-top: 0;
    padding-bottom: 0;
}
.Links>div>span {
    margin-left: -10px;
}
.MyProfile a:hover {
    color: var(--link-hover-color);
}
.MyProfile svg {
    padding-right: 0.7rem;
}

@media (min-width:768px) {
	.MyProfile {
        min-width:250px;
	}
}