.MainButton {
    width: 100%;
}
.MainButton button{
    width: 100%;
    color: var(--button-text-color);
    background-color: var(--button-background-color);
    padding: 0.5rem 0.8rem;
    padding-left: 1.3rem;
    box-sizing: border-box;
    transition: all 150ms ease-in;
    transition-property: background-color, color;
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
    position: relative;
    text-align: center;
    box-shadow: 0 4px 12px var(--button-box-shadow);
}
.MainButton button:disabled {
    background-color: var(--button-disabled-background-color);
    color: var(--button-text-color);
}
.MainButton button:hover {
    background-color: var(--button-background-color);
}
.WithArrow button svg {
    display: none;
}

/* @media (max-width:600px) {
    .MainButton button{
        font-size: 14px;
    }
} */
@media (min-width:768px) {
    .WithArrow button span{
        transition: transform 150ms ease-out;
        transform: translateX(5px);
    }
    .WithArrow button:hover span{
        transform: translateX(0);
    }
    .WithArrow button svg {
        display: block;
        transform: scaleX(0) translateX(0);
        transform-origin: 0% 100%;
        transition: all 150ms ease-in;
        transition-property:  transform, opacity;
        opacity: 0;
        color: var(--button-text-color);
    }
    .WithArrow button:hover svg {
        transform: scaleX(1) translateX(15px);
        opacity: 1;
    } 
}