.Toolbar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    z-index: 90;
    box-shadow: 0 0 18px 0 var(--box-shadow-soft);
    background-color: var(--background-color);
    border-bottom: var(--border-navigation);
}
.NavButtons {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    justify-content: space-between;
    max-width: 200px;
    box-sizing: border-box;
    /* background-color: var(--link-hover-bg-color); */
    /* background-color: #E4E6EB; */
    /* border-radius: 10px; */
    /* box-shadow: inset 0 0 5px 0 var(--box-shadow-soft); */
}
/* .Toolbar nav {
    height: 100%;
} */
.Logo {
    position: relative;
    height: 100%;
    /* width: 100px; */
    width: 281px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    border-right: var(--border-navigation);
}
.NavButtonItem {
    height: 100%;
    /* border-left: var(--border-separator); */
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    padding: 8px 16px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    user-select: none;
    color: var(--title-color);
    color: var(--link-color);
    box-sizing: border-box;
    transition: background-color 150ms ease-out;
    /* border-radius: 50%; */
    /* margin: 0 8px; */
}
.NavButtonItem:hover {
    background-color: var(--link-hover-bg-color);
}

.AvatarHolder {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--title-color);
    background-color: var(--link-hover-bg-color);
}
.AvatarHolder img {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    /* border: 1px solid rgba(150, 150, 150, 0.37); */
    /* border: var(--border-separator); */
    object-fit: cover;
    box-sizing: border-box;
}
.Search {
    /* width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    box-sizing: border-box; */
    /* color: #333; */
    /* background-color: var(--link-hover-bg-color); */
    /* padding: 0.25rem; */
}
.Search svg{
    /* width: 2.5rem;
    height: 2.5rem; */
    /* box-shadow: 0 0 18px 0 var(--box-shadow-softest); */
    /* border: var(--border-separator); */
    box-sizing: border-box;
}
.NavButtonContainer {
    height: 100%;
    /* position tooltip menu to parent */
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:768px) {
    .NavButtonContainer {
        position: unset;
    }
}
@media (max-width:1000px) {
    .Logo {
        border-right: none;
    }
    /* .NavButtonItem {
        padding: 0 1.3rem;
    } */
}
@media (max-width:600px) {
    /* .NavButtonItem {
        padding: 0 0.7rem;
    } */
}