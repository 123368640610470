.SearchBar {
    min-height: 400px;
    box-sizing: border-box;
}
.SubmitButtons {
    display: grid;
    grid-template-columns:  calc(100% - 3rem) 2.8rem;
    grid-gap: 3px;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
    position: relative;
    box-sizing: border-box;
    margin-top: calc(1rem - 8px);
}
.ResetFormButton {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px !important;
    color: var(--button-text-color) !important;
    background-color: var(--button-background-color) !important;
    box-shadow: 0px 1px 14px 0px var(--box-shadow-soft);
    box-sizing: border-box;
}
.ResetFormButton svg {
    transition: transform 150ms linear;
}
.ResetFormButton:hover svg{
    transform: rotate(-180deg);
}
.BookmarkSearch button {
    width: 100%;
    margin-top: 1rem;
    text-transform: none;
    font-weight: 600;
}
.BookmarkSearch svg {
    padding-right: 5px;
}


@media (max-width:600px) {
    .BookmarkSearch button {
        margin-top: 15px;
    }
    
    .SearchBar {
        min-height: 200px;
    }
    .SearchBar form>div {
        /* margin: 0 !important; */
    }
}