.Content {
    position: relative;
    width: calc(100% - 280px);
    top: 60px;
    left:280px;
    box-sizing: border-box;
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
    scrollbar-width: thin;
    scrollbar-color: #141c281f rgba(255, 255, 255, 1);
    background:var(--content-background);
    min-height: calc(100vh - 50px);
    padding-bottom: 50px;
}
.Content.Fullwidth {
    left:0;
    width: 100%;
}
@media (max-width: 1270px) {
    .Content {
        left: 0;
        width: 100%;
    }
}