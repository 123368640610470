.SideDrawer {
    position: fixed;
    width: 450px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: var(--background-color);
    box-sizing: border-box;
    transition: transform 300ms ease-out;
    border-right: var(--border-navigation);
}
.Items {
    height: calc(100% - 82px);
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

.Logo {
    text-align: center;
    height: 60px;
    width: auto;
    padding: 10px;
    box-sizing: border-box;
}

@media (min-width: 1270px) {
    .SideDrawer {
        display: none;
    }
}