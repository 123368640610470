.Register {
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    /* padding-bottom: 120px; */
}

.FormHolder {
    width: 100%;
    margin: 20px auto;
}
.FormHolder button {
    margin-top: 10px;
}
.Action {
    color: var(--text-color);
}
.Register h2{
    color: var(--title-color);
    transform: scale(1.1);
    margin-bottom: 5px;
}
.Action a {
    color: var(--link-hover-color);
    text-decoration: none;
    transition: all 150ms linear;
}
.Action a:hover {
    color: var(--title-color);
}

@media(min-width:500px) {
    .FormHolder {
        max-width: 500px;
    }
    .Register {
        max-width: 80%;
    }
}