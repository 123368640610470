.SideBar {
    padding: 0;
    margin: 0;
    position: fixed;
    top: 50px;
    left: 0;
    bottom: 0;
    width: 0;
    background-color: var(--background-color);
    box-shadow: 0 0 20px 0 var(--box-shadow-soft);
    z-index: 10;
    border-right: var(--border-navigation);
}
@media (min-width: 1270px) {
    .SideBar {
        width: 280px;
    }
}