.TooltipMenu {
	min-height: 150px;
	border-radius: 4px;
	box-shadow: 0px 1px 4px 0px var(--box-shadow-medium);
	background-color: var(--background-color);
	padding: 0;
	position: absolute;
	top: 100%;
	right: 0;
	text-align: left;
	z-index: 200;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-out;
	transition-property: transform, opacity, visibility;
	pointer-events: none;
	box-sizing: border-box;
}

.TooltipMenu.ScaleIn {
	transform: scale(0.95);
}
.TooltipMenu.ScaleIn.Active {
	transform: scale(1);
}


.TooltipMenu.Active {
	transform: scale(1);
	opacity: 1;
	visibility: visible;
	pointer-events: all;
}

.TooltipMenu.Arrow::after {
	content: '';
	position: absolute;
	border: 6px solid var(--submenu-arrow-color);
	border-color:transparent transparent var(--submenu-arrow-color)  transparent;
	bottom: 100%;
	left: 85%;
	margin-left: 7px;
	transform: translateX(-50%);
	box-sizing: border-box;
	opacity: 1;
	padding: 0;
	pointer-events: none;
}

@media (max-width:768px) {
	.TooltipMenu {
		width: 100% !important;
	}
	.TooltipMenu.Arrow::after {
		display: none;
	}
}