.VerificationWarning {
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    background-color: var(--link-color);
    color: var(--background-color);
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    opacity: 0.9;
}
.Content svg {
    color:  #ff4154;
    padding-right: 5px;
    margin-bottom: -3px;
}
.Content a {
    margin-left: 5px;
    color:  #ff4154;
	filter: drop-shadow(1px 1px 1px var(--title-color));
}
.CloseIcon {
    color: var(--background-color);
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
}