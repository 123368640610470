.DrawerContainer {
    display: block;
    box-sizing: border-box;
}

.RightDrawer {
    background-color: var(--background-color);
    width: 500px;
    max-width: 100vw;
    display: flex; 
    flex-flow: column;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 92;
    transform: translate3d(100%, 0, 0);
    
    box-sizing: border-box;
    transition: all 300ms ease-out;
    transition-property: transform, box-shadow;
    box-shadow: 0px 1px 16px 0px rgba(0,0,0,0);
    /* padding-top: 80px; */
}
.RightDrawer.Expand {
    transform: translate3d(0%, 0, 0);
    box-shadow: 0px 1px 16px 0px rgba(0,0,0,0.3);
}
.CloseButton {
    margin-bottom: 10px;
    /* padding-left: 20px; */
    padding :20px;
    height: 60px;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    background-color: var(--background-color);
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 150ms ease-out;
    box-shadow: 0px 1px 8px 0px var(--box-shadow-soft);

    display: flex;
    align-items: center;
}
.CloseButton svg {
    color: var(--link-color);
    transition: all 150ms ease-out;
    transition-property: color, transform;
}
.CloseButton:hover  svg{
    color: var(--link-hover-color);
    transform: translateX(20px);
}
.Backdrop {
    position: fixed;
    z-index: 91;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background 300ms ease-out;
    pointer-events: none;
    transform: translate3d(0, 0, 0);
}
.Backdrop.Expand {
    background-color: rgba(0, 0, 0, 0.25);
    pointer-events: all;
}


@media (max-width:768px) {
    .RightDrawer {
        font-size: 0.8rem;
    }
}