.DrawerToggle {
    height: 100%;
    /* border-left: var(--border-separator); */
    display: flex;
    align-items: center;
    padding: 0 10px;
    padding: 8px 16px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    color: var(--link-color);
    transition: background-color 150ms ease-out;
}
.DrawerToggle:hover {
    background-color: var(--link-hover-bg-color);
}

.DrawerToggle i {
    /* width: 2.5rem; */
    /* height: 2.5rem; */
    /* display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 3px;
    position: relative; */
}


@media (min-width: 1270px) {
    .DrawerToggle {
        display: none;
    }
}