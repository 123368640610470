.MultiSearch {
    min-height: 466px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    margin: 0 auto;
}
/* .MultiSearch h2 {
    margin: 0;
    color: var(--title-color);
    text-align: center;
} */

.Nav {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}
.NavItem {
    margin: 0;
    padding: 0.675rem;
    text-align: center;
    /* background-color: var(--link-color); */
    /* color: var(--background-color); */
    background-color: var(--background-color);
    color: var(--link-hover-color);
    width: 50px;
    /* border: var(--border-separator); */
    transition: all 150ms linear;
    transition-property: border, color, background, opacity;
    cursor: pointer;
    flex-grow: 1;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    opacity: 0.3;
}
.NavItem.Active {
    /* background-color: var(--background-color); */
    /* color: var(--link-hover-color); */
    border-bottom:  transparent;
    
    opacity: 1;
}
.SearchBar {
    box-sizing: border-box;
    background-color: var(--background-color);
    margin-top: -1px;
    padding: 0.675rem;
    width: 100%;
    height: 100%;
    /* border: var(--border-separator); */
}

@media (max-width:600px) {
    .MultiSearch {
        min-height: 330px;
        
    }
}