.PageTitle {
    width: 100%;
    margin-bottom: 30px;
    background-image: url('../../assets/images/hero.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-sizing: border-box;
}
.PageTitle h2 {
    color: var(--title-color);
	/* filter: var(--drop-shadow); */
    margin: 0;
}

.PageTitleOverlay {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    background:#fff;
    background:var(--pagetitle-overlay-background);
    box-shadow: inset 0px 1px 10px 0px var(--box-shadow-soft);
}

@media (min-width:500px) {
    .PageTitle h2 {
        padding: 1rem;
    }
}

@media (min-width:600px) {
    .PageTitleOverlay {
        justify-content: space-between;
        padding: 3rem;
        flex-flow: unset;
    }
}