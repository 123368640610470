.BreadCrumbs {
    max-width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    list-style-type: none;
    padding: 10px;
    flex-wrap: wrap;
    margin: 1rem 2rem;
    box-shadow: 0 3px 10px var(--box-shadow-medium);
}
.BreadCrumb {
    padding: 0 5px;
    white-space: nowrap;
}
.BreadCrumb a{
    color: #fff;
    text-decoration: none;
}

.Child::before {
    content: "";
    display: inline-block;
    height: 0;
    width: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #aaa;
    font-size: 14px;
    margin-right: 12px;
}