.Buttons {
    margin-top: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}.Buttons button {
    margin-top: 20px;
}
.SaveSearch form {
    padding: 20px;
}