.Backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
    transition: background-color 300ms ease-out;
}
.Backdrop.Show {
    background-color: rgba(0, 0, 0, 0.9);
    pointer-events: all;
}

@media (min-width:600px) {
    .MobileOnly {
        display: none;
    }
}