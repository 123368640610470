@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800&subset=latin-ext);
/* Main Font */
	body, h1, h2, h3, h4, h5, h6,  input[type="text"], input[type="password"], input[type="email"],
	textarea, select, input[type="button"], input[type="submit"], button
	{ font-family: "Nunito", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important; text-transform: none; }

html{
  font-size: 14px;
  padding: 0;
  margin: 0;
  background-color: var(--background-color);
}

@media only screen and (min-width: 480px) and (max-width: 767px){ 
  html{
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){ 
  html{
    font-size: 15px;
    background-color: var(--background-color);
  }
}

@media only screen and (min-width: 1270px){ 
  html{
    font-size: 16px;
  }
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}
.no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--title-color);
}
.no-results svg {
  color: var(--info-color);
  padding-right: 10px;
}
body {
  background-color: var(--background-color);
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  transition: all 150ms linear;

  /* COLORS */
  --background-color: #fff;
  --success-color:#2ea0fd;
  --text-color: rgb(100, 100, 100);
  --text-shadow-glow: rgb(255, 255, 255, 1);
  --drop-shadow: drop-shadow(2px 2px 2px #000000c4);
  --drop-shadow-logo: drop-shadow(2px 2px 2px transparent);
  --box-shadow-softest: rgba(0,0,0,0.06);
  --box-shadow-soft: rgba(0,0,0,0.12);
  --box-shadow-medium: rgba(0,0,0,0.22);
  --box-shadow-hard: rgba(0,0,0,0.32);
  --button-box-shadow: #8007131a;
  --tender-info-background: linear-gradient(to left, rgba(0, 0, 0, 0.06) 0%, rgba(255,255,255,0));
  --tender-info-background-hover: linear-gradient(to left,rgba(54, 189, 120, 0.13) 0%, rgba(255,255,255,0));
  --contact-card-title-background: linear-gradient(to left, rgba(0, 0, 0, 0.06) 0%, rgba(255,255,255,0));
  --tender-document-background: rgba(233, 233, 233, 0.644);
  --tender-document-background-hover: #2a41e8;
  --content-background:  #f0f2f5;
  --content-background:  #fafafa;
  --pagetitle-overlay-background: linear-gradient(
    to right, 
    #fafafa 10%, 
    #fafafaea 50%,
    #fafafad5  90%
  );
  --header-overlay-background: linear-gradient(
    to right, 
    #fafafa 10%, 
    #fafafa8f 50%,
    #fafafa3b  90%
  );
  --link-color: rgb(119, 119, 119);
  --link-hover-color: #2a41e8;
  --link-hover-color:#800713;
  /* --link-hover-bg-color: #2a40e810; */
  --link-hover-bg-color: #e4e8ec;
  --link-hover-bg-color: rgb(245, 245, 245);
  --info-color: #2ea0fd;
  --info-border-color: #2ea0fdb2;
  --submenu-arrow-color: #a8a8a8;
  --submenu-arrow-bg-color: #f0f0f0;
  --submenu-arrow-hover-color:rgba(232, 42, 42, 0.295)8;
  --submenu-arrow-hover-bg-color: #8f8f8f65;
  --footer-separator: 1px solid #525252;
  --border-separator: 1px solid #e0e0e0;
  --border-separator-color: #e0e0e0;
  --border-navigation: 1px solid transparent;
  --button-background-color: #2a41e8;
  --button-background-color: #333;
  --button-text-color: #fff;
  --toggle-button-background-color: #2a41e8;
  --toggle-button-background-color: #800713;
  --toggle-button-text-color: #fff;
  --button-disabled-background-color: #757575;
  --tooltip-background-color: #333;
  --tooltip-color: #fff;
  --placeholder-color: #8d8d8d;
  --label-color: rgb(155, 155, 155);
  --select-groupName-color: #fff;
  --select-groupName-hover-color: rgba(160, 160, 160, 0.199);
  --title-color: rgb(51, 51, 51);
  --location-color: #6e6e6e;
  --tag-color: rgb(105, 105, 105);
  --toggle-btn-bg-selected: rgba(54, 118, 170, 0.432);
  --toggle-btn-selected: #fff;
  --notification-bg-color: #444;
  --notification-text-color: #fff;
  --notification-border: 2px solid #444;
  --option-hover-bg-color: #e4e8ec;
  --search-input-background-color: rgb(243, 243, 243);
  --input-text-color: rgb(122, 122, 122);
  --input-border-color: rgba(0, 0, 0, 0.23);
  --detail-item-background:linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1));
  --news-card-content-bg: rgba(255, 255, 255, 0.9);
}
body.night {
  /* COLORS */
  --background-color: #424242;
  --success-color: rgba(255, 255, 255, 0.774);
  --text-color: rgb(206, 206, 206);
  --text-shadow-glow: transparent;
  --drop-shadow: drop-shadow(2px 2px 2px transparent);
  /* --drop-shadow-logo: drop-shadow(1px 1px 1px #fff); */
  --box-shadow-softest: rgba(255, 255, 255, 0);
  --box-shadow-soft: rgba(255, 255, 255, 0);
  --box-shadow-medium: rgba(255,255,255,0);
  --box-shadow-hard: rgba(255,255,255,0);
  --button-box-shadow: #ffffff0a;
  --tender-info-background: linear-gradient(to left, rgba(0, 0, 0, 0.06) 0%, rgba(255,255,255,0));
  --tender-info-background-hover: linear-gradient(to left,rgba(54, 189, 120, 0.13) 0%, rgba(255,255,255,0));
  --contact-card-title-background: linear-gradient(to left, rgba(0, 0, 0, 0.06) 0%, rgba(255,255,255,0));
  --tender-document-background: rgba(233, 233, 233, 0.644);
  --tender-document-background-hover: #2a41e8;
  /* --content-background:  #f0f2f5; */
  --content-background:  #303030;
  --pagetitle-overlay-background: linear-gradient(
    to right, 
    #303030 10%, 
    #303030a2 50%,
    #30303048  90%
  );
  --header-overlay-background: linear-gradient(
    to right, 
    #303030 10%, 
    #3030308f 50%,
    #3030303b  90%
  );
  --link-color: rgb(240, 240, 240);
  --link-hover-color:#2ea0fd;
  --link-hover-color: #ff4154;
  /* --link-hover-bg-color: #2a40e810; */
  --link-hover-bg-color: #64b5f6;
  --link-hover-bg-color: rgb(88, 88, 88);
  --info-color: #2ea0fd;
  --info-border-color: #2ea0fdb2;
  --submenu-arrow-color: rgb(211, 211, 211);
  --submenu-arrow-bg-color: #97979765;
  --submenu-arrow-hover-color:rgb(255, 255, 255);
  --submenu-arrow-hover-bg-color: #818181ee;
  --footer-separator: 1px solid#6d6d6d;
  --border-separator: 1px solid#ffffff65;
  --border-separator-color:#ffffff65;
  --border-navigation: 1px solid  #6d6d6d;
  --button-background-color: rgb(32, 32, 32);
  --button-text-color: #fff;
  --toggle-button-background-color: #ff4154;
  --toggle-button-text-color: rgb(233, 233, 233);
  --button-disabled-background-color: #757575;
  --tooltip-background-color:rgb(0, 0, 0);
  --tooltip-color: #fff;
  --placeholder-color: #8d8d8d;
  --label-color: rgb(155, 155, 155);
  --select-groupName-color: #fff;
  --select-groupName-hover-color: rgba(160, 160, 160, 0.199);
  --title-color: rgb(218, 218, 218);
  --location-color:  #9e9e9e;
  --tag-color:  #4b6774;
  --toggle-btn-bg-selected: rgba(54, 118, 170, 0.432);
  --toggle-btn-selected: #fff;
  --notification-bg-color: rgb(126, 126, 126);
  --notification-text-color: rgb(255, 255, 255);
  --notification-border: 2px solid #444;
  --option-hover-bg-color: #e4e8ec;
  --search-input-background-color: rgb(243, 243, 243);
  --input-text-color: rgb(122, 122, 122);
  --input-border-color: rgba(0, 0, 0, 0.23);
  --detail-item-background:linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1));
  --news-card-content-bg: rgba(0, 0, 0, 0.85);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* inputs */
::-webkit-input-placeholder {
  color: var(--placeholder-color);
}
:-ms-input-placeholder {
  color: var(--placeholder-color);
}
::-ms-input-placeholder {
  color: var(--placeholder-color);
}
::placeholder {
  color: var(--placeholder-color);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* MUI styles */

/* outlined inputs */
fieldset {
  transition : all 150ms ease-in-out !important;
}
.MuiOutlinedInput-root.Mui-focused fieldset,
.MuiOutlinedInput-root.Mui-focused:hover fieldset {
  border-width: 1px !important;
  border-color: var(--link-color) !important;
}
.MuiOutlinedInput-root fieldset {
  border-width: 1px !important;
  border: var(--border-separator) !important;
}
.MuiOutlinedInput-root:hover fieldset {
  border-color: var(--link-color) !important;
}
.MuiOutlinedInput-root {
  color:  var(--text-color) !important;
}
.MuiOutlinedInput-root.Mui-error fieldset,
.MuiOutlinedInput-root.Mui-error:hover fieldset {
  border-width: 1px !important;
  border-color: red !important;
}
/* underlined inputs - textfields */
.MuiInput-underline input {
  color: var(--title-color)
}
.MuiInput-underline::before {
  border-bottom-color: var(--border-separator-color) !important;
}
.MuiInput-underline::after {
  border-bottom-color: var(--link-hover-color) !important;
}
/* triangle select-icon */
.MuiOutlinedInput-root  .MuiIconButton-root {
  color : unset !important;
}
/* labels */
label {
  transition: color 600ms ease-out;
}
label.MuiInputLabel-root{
  color: var(--link-color) !important;
  color: var(--label-color) !important;
}
label.Mui-focused{
  color: var(--link-color) !important;
}

label.Mui-error{
  color: red !important;
}
/* date input */
.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected{
  background-color: var(--link-hover-color) !important;
}
.MuiDialogActions-root span {
  color:var(--link-hover-color) !important;
}


/* select - options container */
.MuiAutocomplete-paper {
  border: 1px solid #3f51b5;
  border: 1px solid var(--link-color);
  background-color: red;
}
.MuiAutocomplete-popper>div{
  background-color: var(--background-color) !important;
}

/* options */
.MuiAutocomplete-option {
  padding: 15px !important;
  background-color:var(--background-color);
  color:  var(--link-color) 
}
.MuiAutocomplete-option[data-focus="true"] {
  color: var(--link-hover-color);
} 
.MuiAutocomplete-option:hover {
  color: var(--link-hover-color);
}
.MuiAutocomplete-groupLabel {
  background-color: var(--link-hover-bg-color) !important;
  color: var(--link-hover-color) !important;
  border-bottom: var(--border-separator);
}
.MuiAutocomplete-noOptions {
  color: var(--link-color) !important;
}

/* outline buttons */
.MuiButton-outlinedPrimary {
  color: var(--link-color) !important;
  border: 1px solid var(--link-color) !important;
  transition: all 150ms ease-out !important;
  transition-property: border, color;
}
.MuiButton-outlinedPrimary:disabled {
  color: rgb(114, 114, 114) !important;
  border: 1px solid rgb(114, 114, 114) !important;
}

.myMuiConfirm {
  color: var(--success-color) !important;
  border: 1px solid var(--success-color) !important;
  transition: all 150ms ease-out !important;
  transition-property: border, color;
}
.myMuiConfirm:disabled {
  color: rgb(114, 114, 114) !important;
  border: 1px solid rgb(114, 114, 114) !important;
}

.myMuiButton {
  color: var(--title-color) !important;
  border: 1px solid var(--title-color) !important;
  transition: all 150ms ease-out !important;
  transition-property: border, color;
}
.myMuiButton:disabled {
  color: rgb(114, 114, 114) !important;
  border: 1px solid rgb(114, 114, 114) !important;
}
/* .MuiTextField-root {
  color: var(--link-hover-color) !important;
  border-color: var(--link-hover-color) !important;
} */

/* pagination styles */
/* .MuiPagination-ul li{
  color: var(--title-color) !important;
} */
button.MuiPaginationItem-root.Mui-selected {
  color: #fff;
  background-color: var(--button-background-color);
}
button.MuiPaginationItem-root:hover {
  color: #fff;
  background-color: var(--button-background-color) !important;
}
button.MuiPaginationItem-root {
  color: var(--title-color);
}
.MuiPaginationItem-ellipsis {
  color: var(--title-color) !important;
}
/* switch button */
.MuiSwitch-colorPrimary.Mui-checked {
  color: var(--button-background-color) !important;
}
/* ******************************* end MUI ***************************************** */

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(143, 55, 55, 0);
  background: var(--background-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #141c2818; 
  background: var(--link-color); 
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #141c283d;  */
  background: var(--link-hover-color);
}
/* ******** */

/* non selectable link text */
a {
  -webkit-touch-callout : none;     /* iOS Safari */
  -webkit-user-select   : none;     /* Chrome/Safari/Opera */
  -khtml-user-select    : none;     /* Konqueror */
  -moz-user-select      : none;     /* Firefox */
  -ms-user-select       : none; 
  
  text-decoration: none !important;
  
  user-select: none !important;
  -webkit-tap-highlight-color: transparent !important;

}
div, h1, h2, h3, h4, span, p , button{
  -webkit-tap-highlight-color: transparent !important;
}
/* svg, span.material-icons {
  padding-right: 15px;
} */

/* PAGINATION */
.pagination {
  background-color: var(--background-color);
  position: fixed;
  bottom: 0;
  left: 50%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, 150%);
  transition: transform 150ms ease-out;
  border-radius: 4px 4px 0 0;
  z-index: 10;
  border: var(--border-separator);
  border-bottom: none;
}
.pagination.show {
  transform: translate(-50%, 0);
}

/* RIPPLE EFFECT */
.ripple {
  position:relative;
  overflow: hidden;
  transform:translate(0, 0, 0);
}
.ripple::after {
  content:'';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple:active::after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

/* TOOLTIPS */
.tooltipLeft {
  position: relative;
}
.tooltipLeft::after{            
  content: attr(data-tooltip);
  font-size: 0.8rem;
  display: block;
  position: absolute;
  top: 50%;
  right: calc(90% + 11px);
  transform: translateY(-50%);
  background-color: var(--tooltip-background-color);
  color: var(--tooltip-color);
  padding: 0.5rem;
  border-radius: 4px;
  opacity: 0;
  transition: all 150ms ease-in;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  pointer-events: none;
  text-align: center;
  white-space:nowrap;
}
.tooltipLeft:hover::after{
  right: calc(100% + 11px);
  opacity: 1;
  z-index: 50;
}

.tooltipLeft::before {
  content: '';
  position: absolute;
  border: 6px solid #333;
  border-color: transparent transparent transparent var(--tooltip-background-color);
  top: 50%;
  right: 90%;
  transform: translateY(-50%);
  box-sizing: border-box;
  opacity: 0;
  transition: all 150ms ease-in;
  padding: 0;
  pointer-events: none;
}
.tooltipLeft:hover::before{
  right: 100%;
  opacity: 1;
  z-index: 50;
}
/* tooltip bottom-left */
.tooltipBottomLeft {
  position: relative;
}
.tooltipBottomLeft::after{            
  content: attr(data-tooltip);
  font-size: 0.8rem;
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  /* transform: translateX(-50%); */
  background-color: var(--tooltip-background-color);
  color: var(--tooltip-color); 
  padding: 0.5rem;
  border-radius: 4px;
  opacity: 0;
  transition: all 150ms ease-in;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  pointer-events: none;
  text-align: center;
  white-space:nowrap;
  z-index: 500;
}
.tooltipBottomLeft:hover::after{
  top: calc(100% + 12px);
  opacity: 1;
  /* z-index: 50; */
}

.tooltipBottomLeft::before {
  content: '';
  position: absolute;
  border: 6px solid #333;
  border-color: transparent transparent var(--tooltip-background-color) transparent;
  top: calc(100% - 12px);
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  opacity: 0;
  transition: all 150ms ease-in;
  padding: 0;
  pointer-events: none;
}
.tooltipBottomLeft:hover::before{
  top: calc(100%);
  opacity: 1;
  z-index: 50;
}

.tooltipRight {
  position: relative;
}
.tooltipRight::after{            
  content: attr(data-tooltip);
  font-size: 0.8rem;
  display: block;
  position: absolute;
  top: 50%;
  left: calc(90% + 12px);
  transform: translateY(-50%);
  background-color: var(--tooltip-background-color);
  color: var(--tooltip-color);
  padding: 0.5rem;
  border-radius: 4px;
  opacity: 0;
  transition: all 150ms ease-in;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  pointer-events: none;
  text-align: center;
  white-space:nowrap;
  z-index: 500;
}
.tooltipRight:hover::after{
  left: calc(100% + 12px);
  opacity: 1;
  /* z-index: 50; */
}

.tooltipRight::before {
  content: '';
  position: absolute;
  border: 6px solid #333;
  border-color: transparent var(--tooltip-background-color) transparent transparent ;
  top: 50%;
  left: 90%;
  transform: translateY(-50%);
  box-sizing: border-box;
  opacity: 0;
  transition: all 150ms ease-in;
  padding: 0;
  pointer-events: none;
}
.tooltipRight:hover::before{
  left: 100%;
  opacity: 1;
  z-index: 50;
}

.tooltipBottom {
  position: relative;
}
.tooltipBottom::after{            
  content: attr(data-tooltip);
  font-size: 0.8rem;
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--tooltip-color);
  color: var(--tooltip-background-color); 
  padding: 0.5rem;
  border-radius: 4px;
  opacity: 0;
  transition: all 150ms ease-in;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  pointer-events: none;
  text-align: center;
  white-space:nowrap;
  z-index: 500;
}
.tooltipBottom:hover::after{
  top: calc(100% + 12px);
  opacity: 1;
  /* z-index: 50; */
}

.tooltipBottom::before {
  content: '';
  position: absolute;
  border: 6px solid #333;
  border-color: transparent transparent var(--tooltip-color) transparent;
  top: calc(100% - 12px);
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  opacity: 0;
  transition: all 150ms ease-in;
  padding: 0;
  pointer-events: none;
}
.tooltipBottom:hover::before{
  top: calc(100%);
  opacity: 1;
  z-index: 50;
}


/* after element on hover */
.hover-underline {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.hover-underline::after {
  position: absolute;
  content: '';
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ff4154;
  /* transition: width 150ms ease-out; */
  transition: transform 300ms ease-out;
  transform: scaleX(0);
  transform-origin: right;
}
.hover-underline:hover::after {
  /* width: 100%; */
  transform: scaleX(1);
  transform-origin: left;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all 500ms ease;
}


.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
/* .menu-secondary-exit {

} */
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all 500ms ease;
}
.menu {
  width: 100%;
}

@media (max-width:600px) {
	.tooltipRight::after,
	.tooltipRight::before,
	.tooltipLeft::after,
	.tooltipLeft::before {
    display: none;
  }
}
.backdrop {
  opacity: 0;
  background-color: black;
}

/* animations */

.fadeIn {
  -webkit-animation: fadeIn 300ms ease-in;
          animation: fadeIn 300ms ease-in;
}
@-webkit-keyframes fadeIn {
  0%{opacity:0}
  100%{opacity:1;}
}
@keyframes fadeIn {
  0%{opacity:0}
  100%{opacity:1;}
}

.scaleIn {
  -webkit-animation: scaleIn 300ms ease-out;
          animation: scaleIn 300ms ease-out;
}
@-webkit-keyframes scaleIn {
  0%{transform:scale(0.97);}
  50%{transform:scale(1.01);}
  100%{transform:scale(1);}
  
  /* 0%{
    transform:translateY(-30px);
    opacity:0;
  }
  50%{
    transform:translateY(10px);
  }
  100%{
    transform:translateY(0);
    opacity:1;
  } */
}
@keyframes scaleIn {
  0%{transform:scale(0.97);}
  50%{transform:scale(1.01);}
  100%{transform:scale(1);}
  
  /* 0%{
    transform:translateY(-30px);
    opacity:0;
  }
  50%{
    transform:translateY(10px);
  }
  100%{
    transform:translateY(0);
    opacity:1;
  } */
}

.paginationScaleIn {
  -webkit-animation: paginationScaleIn 300ms ease-out;
          animation: paginationScaleIn 300ms ease-out;
}
@-webkit-keyframes paginationScaleIn {
  0%{transform: scale(0.97) translate(-50%);}
  50%{transform: scale(1.03) translate(-50%);}
  100%{transform: scale(1) translate(-50%);}
}
@keyframes paginationScaleIn {
  0%{transform: scale(0.97) translate(-50%);}
  50%{transform: scale(1.03) translate(-50%);}
  100%{transform: scale(1) translate(-50%);}
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid green; */
  -webkit-text-fill-color: var(--text-color);
  /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.SideDrawer_SideDrawer__yCe4O {
    position: fixed;
    width: 450px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: var(--background-color);
    box-sizing: border-box;
    transition: transform 300ms ease-out;
    border-right: var(--border-navigation);
}
.SideDrawer_Items__3-5aH {
    height: calc(100% - 82px);
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.SideDrawer_Open__1iWD7 {
    transform: translateX(0);
}

.SideDrawer_Close__K4362 {
    transform: translateX(-100%);
}

.SideDrawer_Logo__3ib50 {
    text-align: center;
    height: 60px;
    width: auto;
    padding: 10px;
    box-sizing: border-box;
}

@media (min-width: 1270px) {
    .SideDrawer_SideDrawer__yCe4O {
        display: none;
    }
}
.Logo_Logo__3_5qz {
    display: flex;
    justify-content: center;
    align-items: center;
	height: 100%;
	width: 100%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;

    
    /* height: 500px; */
    /* background-color: #000; */

    /* background-position: center;
    background-size: contain;
    background-repeat: no-repeat; */
	/* filter: var(--drop-shadow-logo); */
}
.Logo_LogoImage__3yylL {
    display: block;
	height: 100%;
	width: auto;
	/* filter: var(--drop-shadow-logo); */
    object-fit:cover;
    object-position:50% 50%;
}
.Logo_NightLogoImage__q-WWn {
    display: block;
	height: 100%;
    width: auto;
    object-fit:cover;
    object-position:50% 50%;
	/* filter: var(--drop-shadow-logo); */
}
.NavigationItems_NavigationItems__3NKz2 {
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box; 
    position: relative;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #141c281f rgba(255, 255, 255, 1);
    font-size: 15px;
    height: 100%;
}
.NavigationItems_NavigationItems__3NKz2 svg, 
.NavigationItems_NavigationItems__3NKz2 span,
.NavigationItems_NavigationItems__3NKz2 i {
    padding-right: 15px !important;
}
.NavigationItems_GroupTitle__2dqBP {
    padding:30px;
    padding-bottom: 10px;
    color: var(--link-hover-color);
    font-size: 14px;
}
.NavigationItems_SubmenuContainer__m6SJr {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    color: rgb(124, 124, 124);
    color: red;
    transition: all 150ms ease-out;
    transition-property: color, background, border;
    border-left: 3px solid transparent;
    cursor: pointer;
}
.NavigationItems_SubmenuContainer__m6SJr:hover {
    /* color: var(--link-hover-color); */
    background-color: var(--link-hover-bg-color);
    border-left: 3px solid var(--link-hover-color);
}
.NavigationItems_SubmenuContainer__m6SJr.NavigationItems_Active__26E8f {
    /* color: var(--link-hover-color); */
    background-color: var(--link-hover-bg-color);
    border-left: 3px solid var(--link-hover-color);
}
/* .SubmenuContainer.Active .ParentLink,
.SubmenuContainer.Active .Submenu li a{
    color: var(--link-hover-color);
} */

.NavigationItems_Submenu__3BIzg {
    padding: 0;
    margin: 0;
	max-height: 0;
    pointer-events: none;
	opacity: 0;
	line-height: 5px;
	transition: all 300ms ease-out;
    transition-property: padding, opacity, max-height;
}
.NavigationItems_Submenu__3BIzg button {
    display: block;
    width: 100%;
}

.NavigationItems_Submenu__3BIzg.NavigationItems_Expand__1YQkI {
	/* padding: 0 0 18px 0; */
    padding-bottom: 20px;
	opacity: 1;
	max-height: 200px;
	pointer-events: all;
}

.NavigationItems_Submenu__3BIzg li{
    /* transition: all 150ms ease-out; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.NavigationItems_Submenu__3BIzg li a {
    padding: 15px 0;
    padding-left: 60px;
    width: 100%;
    height: 100%;
    display: block;
    transition: all 150ms ease-out;
    transition-property: color, transform, background, border;
}

.NavigationItems_Submenu__3BIzg li a:hover{
    /* color: var(--link-hover-color); */
    transform: translateX(5px);
    background-color: #2a40e800 !important;
    border-left: 3px solid #2a40e800 !important;
}

.NavigationItems_ParentLink__XDTCk {
    position:relative;
    padding: 15px;
    padding-left: 30px;
    color: var(--link-color);
    text-decoration: none;
    width: 100%;
    height: 50px;
    /* height: 50px; */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: color 150ms ease-out;

    -webkit-touch-callout : none;     /* iOS Safari */
    -webkit-user-select   : none;     /* Chrome/Safari/Opera */
    -khtml-user-select    : none;     /* Konqueror */
    -moz-user-select      : none;     /* Firefox */
    -ms-user-select       : none; 
}

.NavigationItems_ParentLink__XDTCk:hover {
    /* color: var(--link-hover-color); */
}
.NavigationItems_ParentLink__XDTCk::after {
    font-family: "Material-Icons";
    content: '▾';
    font-size: 15px;
    transition: all 150ms ease-out;
    transition-property: color, background;
    line-height: 10px;
    background-color: var(--submenu-arrow-bg-color);
    color: var(--submenu-arrow-color);
    display: inline-block;
    padding: 3px;
    border-radius: 3px;
    margin-left: 7px;
    text-indent: 0;
    position: absolute;
    right: 35px;
}
.NavigationItems_SubmenuContainer__m6SJr:hover .NavigationItems_ParentLink__XDTCk::after,
.NavigationItems_SubmenuContainer__m6SJr.NavigationItems_Active__26E8f .NavigationItems_ParentLink__XDTCk::after {
    background-color: var(--submenu-arrow-hover-bg-color);
    color: var(--submenu-arrow-hover-color)
}
.NavigationItem_NavigationItem__1mOu6 {
    box-sizing: border-box;
    width: 100%;
    height:3.1rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-self:stretch;
    overflow: hidden;
}
.NavigationItem_NavigationLink__2_dUm {
    padding: 1rem;
    padding-left: 2rem;
    color: var(--link-color);
    text-decoration: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: all 300ms ease;
    transition-property: color, background, border;
    border-left: 3px solid transparent;
}
.NavigationItem_NavigationLink__2_dUm:hover{
    /* color: var(--link-hover-color); */
    background-color: var(--link-hover-bg-color);
    border-left: 3px solid var(--link-hover-color);
}
.NavigationItem_NavigationLink__2_dUm:active, .NavigationItem_active__1gbiT{
    /* color: var(--link-hover-color); */
    border-left: 3px solid var(--link-hover-color);
    background-color:  var(--link-hover-bg-color);
}
.NavigationItem_Active__scNgk {
    /* color: var(--link-hover-color); */
    background-color:  var(--link-hover-bg-color);
    border-left: 3px solid var(--link-hover-color);
}
.NavigationItem_ChildActive__Y1XLI, .NavigationItem_NavigationLink__2_dUm.NavigationItem_ChildActive__Y1XLI:hover {
    /* color: var(--info-color) !important; */
    /* color: rgb(116, 158, 255) !important; */
    font-weight: 700;
}
.Backdrop_Backdrop__H54bF {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
    transition: background-color 300ms ease-out;
}
.Backdrop_Backdrop__H54bF.Backdrop_Show__3X0SQ {
    background-color: rgba(0, 0, 0, 0.9);
    pointer-events: all;
}

@media (min-width:600px) {
    .Backdrop_MobileOnly__1G_f1 {
        display: none;
    }
}
.Toolbar_Toolbar__1VxXf {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    z-index: 90;
    box-shadow: 0 0 18px 0 var(--box-shadow-soft);
    background-color: var(--background-color);
    border-bottom: var(--border-navigation);
}
.Toolbar_NavButtons__3JXvI {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    justify-content: space-between;
    max-width: 200px;
    box-sizing: border-box;
    /* background-color: var(--link-hover-bg-color); */
    /* background-color: #E4E6EB; */
    /* border-radius: 10px; */
    /* box-shadow: inset 0 0 5px 0 var(--box-shadow-soft); */
}
/* .Toolbar nav {
    height: 100%;
} */
.Toolbar_Logo__VZCDW {
    position: relative;
    height: 100%;
    /* width: 100px; */
    width: 281px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    border-right: var(--border-navigation);
}
.Toolbar_NavButtonItem__2zrRq {
    height: 100%;
    /* border-left: var(--border-separator); */
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    padding: 8px 16px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: var(--title-color);
    color: var(--link-color);
    box-sizing: border-box;
    transition: background-color 150ms ease-out;
    /* border-radius: 50%; */
    /* margin: 0 8px; */
}
.Toolbar_NavButtonItem__2zrRq:hover {
    background-color: var(--link-hover-bg-color);
}

.Toolbar_AvatarHolder__3_n7v {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--title-color);
    background-color: var(--link-hover-bg-color);
}
.Toolbar_AvatarHolder__3_n7v img {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    /* border: 1px solid rgba(150, 150, 150, 0.37); */
    /* border: var(--border-separator); */
    object-fit: cover;
    box-sizing: border-box;
}
.Toolbar_Search__3dl5N {
    /* width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    box-sizing: border-box; */
    /* color: #333; */
    /* background-color: var(--link-hover-bg-color); */
    /* padding: 0.25rem; */
}
.Toolbar_Search__3dl5N svg{
    /* width: 2.5rem;
    height: 2.5rem; */
    /* box-shadow: 0 0 18px 0 var(--box-shadow-softest); */
    /* border: var(--border-separator); */
    box-sizing: border-box;
}
.Toolbar_NavButtonContainer__9ITce {
    height: 100%;
    /* position tooltip menu to parent */
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:768px) {
    .Toolbar_NavButtonContainer__9ITce {
        position: unset;
    }
}
@media (max-width:1000px) {
    .Toolbar_Logo__VZCDW {
        border-right: none;
    }
    /* .NavButtonItem {
        padding: 0 1.3rem;
    } */
}
@media (max-width:600px) {
    /* .NavButtonItem {
        padding: 0 0.7rem;
    } */
}
.DrawerToggle_DrawerToggle__3L_rv {
    height: 100%;
    /* border-left: var(--border-separator); */
    display: flex;
    align-items: center;
    padding: 0 10px;
    padding: 8px 16px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    color: var(--link-color);
    transition: background-color 150ms ease-out;
}
.DrawerToggle_DrawerToggle__3L_rv:hover {
    background-color: var(--link-hover-bg-color);
}

.DrawerToggle_DrawerToggle__3L_rv i {
    /* width: 2.5rem; */
    /* height: 2.5rem; */
    /* display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 3px;
    position: relative; */
}


@media (min-width: 1270px) {
    .DrawerToggle_DrawerToggle__3L_rv {
        display: none;
    }
}
.TooltipMenu_TooltipMenu__FE4xv {
	min-height: 150px;
	border-radius: 4px;
	box-shadow: 0px 1px 4px 0px var(--box-shadow-medium);
	background-color: var(--background-color);
	padding: 0;
	position: absolute;
	top: 100%;
	right: 0;
	text-align: left;
	z-index: 200;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-out;
	transition-property: transform, opacity, visibility;
	pointer-events: none;
	box-sizing: border-box;
}

.TooltipMenu_TooltipMenu__FE4xv.TooltipMenu_ScaleIn__BIpc5 {
	transform: scale(0.95);
}
.TooltipMenu_TooltipMenu__FE4xv.TooltipMenu_ScaleIn__BIpc5.TooltipMenu_Active__3yv14 {
	transform: scale(1);
}


.TooltipMenu_TooltipMenu__FE4xv.TooltipMenu_Active__3yv14 {
	transform: scale(1);
	opacity: 1;
	visibility: visible;
	pointer-events: all;
}

.TooltipMenu_TooltipMenu__FE4xv.TooltipMenu_Arrow__29_va::after {
	content: '';
	position: absolute;
	border: 6px solid var(--submenu-arrow-color);
	border-color:transparent transparent var(--submenu-arrow-color)  transparent;
	bottom: 100%;
	left: 85%;
	margin-left: 7px;
	transform: translateX(-50%);
	box-sizing: border-box;
	opacity: 1;
	padding: 0;
	pointer-events: none;
}

@media (max-width:768px) {
	.TooltipMenu_TooltipMenu__FE4xv {
		width: 100% !important;
	}
	.TooltipMenu_TooltipMenu__FE4xv.TooltipMenu_Arrow__29_va::after {
		display: none;
	}
}
.MyProfile_MyProfile__1nz6T {
    width: 100%;
    display: inline-block;
}
.MyProfile_Header__2nhS8 {
    display: flex;
    border-bottom: var(--border-separator);
    padding: 20px 0;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
}
.MyProfile_Avatar__14tDX {
    padding: 0 1.3rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}
.MyProfile_Avatar__14tDX img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid rgba(150, 150, 150, 0.37);
    border: var(--border-separator);
    object-fit: cover;
}
.MyProfile_Username__3JcOs {
    max-width: 200px;
    overflow: hidden;
    box-sizing: border-box;
    margin-right: 1.3rem;
}
.MyProfile_Links__ZWq-s {
    padding: 1.3rem 0;
}
.MyProfile_MyProfile__1nz6T a,
.MyProfile_Links__ZWq-s>div {
    padding: 0.7rem 1.3rem;
    color: var(--link-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: color 150ms ease-in;
}
.MyProfile_Links__ZWq-s>div {
    padding-top: 0;
    padding-bottom: 0;
}
.MyProfile_Links__ZWq-s>div>span {
    margin-left: -10px;
}
.MyProfile_MyProfile__1nz6T a:hover {
    color: var(--link-hover-color);
}
.MyProfile_MyProfile__1nz6T svg {
    padding-right: 0.7rem;
}

@media (min-width:768px) {
	.MyProfile_MyProfile__1nz6T {
        min-width:250px;
	}
}
.MultiSearch_MultiSearch__3FC03 {
    min-height: 466px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    margin: 0 auto;
}
/* .MultiSearch h2 {
    margin: 0;
    color: var(--title-color);
    text-align: center;
} */

.MultiSearch_Nav__3406p {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}
.MultiSearch_NavItem__1DlhK {
    margin: 0;
    padding: 0.675rem;
    text-align: center;
    /* background-color: var(--link-color); */
    /* color: var(--background-color); */
    background-color: var(--background-color);
    color: var(--link-hover-color);
    width: 50px;
    /* border: var(--border-separator); */
    transition: all 150ms linear;
    transition-property: border, color, background, opacity;
    cursor: pointer;
    flex-grow: 1;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
    opacity: 0.3;
}
.MultiSearch_NavItem__1DlhK.MultiSearch_Active__3cqNk {
    /* background-color: var(--background-color); */
    /* color: var(--link-hover-color); */
    border-bottom:  transparent;
    
    opacity: 1;
}
.MultiSearch_SearchBar__3HFMA {
    box-sizing: border-box;
    background-color: var(--background-color);
    margin-top: -1px;
    padding: 0.675rem;
    width: 100%;
    height: 100%;
    /* border: var(--border-separator); */
}

@media (max-width:600px) {
    .MultiSearch_MultiSearch__3FC03 {
        min-height: 330px;
        
    }
}
.CompanySearch_SearchBar__2aCBR {
    min-height: 400px;
    box-sizing: border-box;
}
.CompanySearch_SubmitButtons__2pSWG {
    display: grid;
    grid-template-columns:  calc(100% - 3rem) 2.8rem;
    grid-gap: 3px;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
    position: relative;
    box-sizing: border-box;
    margin-top: calc(1rem - 8px);
}
.CompanySearch_ResetFormButton__J66xd {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px !important;
    color: var(--button-text-color) !important;
    background-color: var(--button-background-color) !important;
    box-shadow: 0px 1px 14px 0px var(--box-shadow-soft);
    box-sizing: border-box;
}
.CompanySearch_ResetFormButton__J66xd svg {
    transition: transform 150ms linear;
}
.CompanySearch_ResetFormButton__J66xd:hover svg{
    transform: rotate(-180deg);
}
.CompanySearch_BookmarkSearch__2DuUW button {
    width: 100%;
    margin-top: 1rem;
    text-transform: none;
    font-weight: 600;
}
.CompanySearch_BookmarkSearch__2DuUW svg {
    padding-right: 5px;
}


@media (max-width:600px) {
    .CompanySearch_BookmarkSearch__2DuUW button {
        margin-top: 15px;
    }
    
    .CompanySearch_SearchBar__2aCBR {
        min-height: 200px;
    }
    .CompanySearch_SearchBar__2aCBR form>div {
        /* margin: 0 !important; */
    }
}
.Notification_Notification__2pFz2 {
    background-color: var(--notification-bg-color);
    color:var(--notification-text-color);
    border: var(--notification-border);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1006;
    transition: all 500ms ease;
    transition-property: opacity, transform;
    margin-bottom: 10px;
    opacity: 0;
    transform: translateY(150%);
    pointer-events: none;
    max-width: 80%;
    min-width: 200px;
    cursor: pointer;
    box-sizing: border-box;
}
.Notification_Show__29Nbb {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
}
.Notification_Notification__2pFz2 svg {
    margin-right: 5px;
}
.Notification_Notification__2pFz2.Notification_Success__22JWH svg{
    color: #2efd50b4;
}
.Notification_Notification__2pFz2.Notification_Danger__2nszJ svg{
    color: #fd2e2e;
}
.Notification_Notification__2pFz2.Notification_Info__1jXo2 svg{
    color: #2ea0fd;
}

@media (max-width:600px) {
	.Notification_Notification__2pFz2 {
        width: 100%;
        max-width: 100%;
        right: 0;
        border-radius: 0;
    }
}
.MainButton_MainButton__3m-jk {
    width: 100%;
}
.MainButton_MainButton__3m-jk button{
    width: 100%;
    color: var(--button-text-color);
    background-color: var(--button-background-color);
    padding: 0.5rem 0.8rem;
    padding-left: 1.3rem;
    box-sizing: border-box;
    transition: all 150ms ease-in;
    transition-property: background-color, color;
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
    position: relative;
    text-align: center;
    box-shadow: 0 4px 12px var(--button-box-shadow);
}
.MainButton_MainButton__3m-jk button:disabled {
    background-color: var(--button-disabled-background-color);
    color: var(--button-text-color);
}
.MainButton_MainButton__3m-jk button:hover {
    background-color: var(--button-background-color);
}
.MainButton_WithArrow__aMWe4 button svg {
    display: none;
}

/* @media (max-width:600px) {
    .MainButton button{
        font-size: 14px;
    }
} */
@media (min-width:768px) {
    .MainButton_WithArrow__aMWe4 button span{
        transition: transform 150ms ease-out;
        transform: translateX(5px);
    }
    .MainButton_WithArrow__aMWe4 button:hover span{
        transform: translateX(0);
    }
    .MainButton_WithArrow__aMWe4 button svg {
        display: block;
        transform: scaleX(0) translateX(0);
        transform-origin: 0% 100%;
        transition: all 150ms ease-in;
        transition-property:  transform, opacity;
        opacity: 0;
        color: var(--button-text-color);
    }
    .MainButton_WithArrow__aMWe4 button:hover svg {
        transform: scaleX(1) translateX(15px);
        opacity: 1;
    } 
}
.Modal_Modal__3Uk_V {
    position: fixed;
    /* position: absolute; */
    left: 0;
    right: 0;
    top: -6rem;
    bottom: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 85vh;
    margin: auto;
    z-index: 500;
    background-color:var(--content-background);
    width: 90%;
    border: var(--border-separator);
    box-shadow: 0px 1px 1rem 0px rgba(0,0,0,0.5);
    padding: 1rem;
    box-sizing: border-box;
    transition: all 150ms ease-out;
    transition-property: top, opacity;
    /* border-radius: 0.6rem 0 0.6rem 0.6rem; */
    border-radius: 0.6rem;
    opacity: 0;
    pointer-events: none;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translate3d(0, 0, 0);
}
.Modal_Modal__3Uk_V.Modal_Show__smaFi {
    top: 0;
    opacity: 1;
    pointer-events: all;
}
.Modal_Overlay__2asbs {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: background 300ms ease-out;
    background-color: transparent;
    pointer-events: none;
    transform: translate3d(0, 0, 0);
}
.Modal_Overlay__2asbs.Modal_Show__smaFi {
    background-color: rgba(0, 0, 0, 0.75);
    pointer-events: all;
}
.Modal_CloseButton__DqJ0- {
    position: fixed;
    top: 0;
    right: 0;
    color: #fff;
    cursor: pointer;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease-out;
}
.Modal_CloseButton__DqJ0-.Modal_Show__smaFi {
    pointer-events: all;
    opacity: 1;
}



@media (min-width: 600px) {
    .Modal_Modal__3Uk_V {
        width: 500px;
        max-height: 90vh;
    }
}
.SaveSearch_Buttons__2ov1L {
    margin-top: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}.SaveSearch_Buttons__2ov1L button {
    margin-top: 20px;
}
.SaveSearch_SaveSearch__1okjm form {
    padding: 20px;
}
.ConfirmAction_ConfirmAction__1VYQF h2 {
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
    color: var(--title-color);
}
.ConfirmAction_Buttons__Iccp6 {
    padding: 1.3rem;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.ConfirmAction_Buttons__Iccp6 button {
    margin-top: 1.3rem;
}
.BookmarkedCompanySearch_BookmarkedCompanySearch__3rvLs {
    height: 60vh;
    overflow: auto;
}
.BookmarkedCompanySearch_Item__2OYy9 {
    margin: 0.7rem;
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 12px 0px var(--box-shadow-soft);
    cursor: pointer;
    transition: border 150ms linear;
    color: var(--text-color);
    background-color: var(--background-color);
    border: 1px solid transparent;
    border-radius: 0.7rem;
}
.BookmarkedCompanySearch_Item__2OYy9:hover {
    border: 1px solid #2ea0fd;
}
.BookmarkedCompanySearch_Label__3m9p6 {
    max-width: 70%;
    overflow: hidden;
}

.BookmarkedCompanySearch_Label__3m9p6::first-letter {
    text-transform: capitalize;
}
.BookmarkedCompanySearch_Icon__3F0r0 {
    transition: color 150ms linear;
    cursor: pointer;
    padding: 5px;
}
.BookmarkedCompanySearch_Icon__3F0r0:hover{
    color: #fd2e2e;
}
.BookmarkedCompanySearch_Icon__3F0r0 svg {
    padding: 0;
}
.BookmarkedCompanySearch_NoItems__i85zW {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
}
.BookmarkedCompanySearch_NoItems__i85zW svg {
    color: var(--info-color);
    margin-right: 5px;
}
@media (min-width: 480px) {
    .BookmarkedCompanySearch_Item__2OYy9 {
        padding: 1.5rem;
        margin: 1.5rem;
    }
}
.Loader_Loader__14syf {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}
.Loader_Loader__14syf.Loader_Button__2xkFA {
    height: 24px;
    width: 24px;
}

.Loader_loader__1I38t,
.Loader_loader__1I38t:before,
.Loader_loader__1I38t:after {
    background: var(--link-hover-color);
    -webkit-animation: Loader_load1__2j7ra 1s infinite ease-in-out;
    animation: Loader_load1__2j7ra 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}
.Loader_loader__1I38t {
    color: var(--link-hover-color);
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.Loader_loader__1I38t.Loader_Button__2xkFA {
    font-size: 3px;
    transform: translateY(2px);
}
.Loader_loader__1I38t:before,
.Loader_loader__1I38t:after {
    position: absolute;
    top: 0;
    content: '';
}
.Loader_loader__1I38t:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.Loader_loader__1I38t:after {
    left: 1.5em;
}
@-webkit-keyframes Loader_load1__2j7ra {
    0%,
    80%,
    100% {
    box-shadow: 0 0;
    height: 4em;
    }
    40% {
    box-shadow: 0 -2em;
    height: 5em;
}
}
@keyframes Loader_load1__2j7ra {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

.RightDrawer_DrawerContainer__2afDr {
    display: block;
    box-sizing: border-box;
}

.RightDrawer_RightDrawer__56BrI {
    background-color: var(--background-color);
    width: 500px;
    max-width: 100vw;
    display: flex; 
    flex-flow: column;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 92;
    transform: translate3d(100%, 0, 0);
    
    box-sizing: border-box;
    transition: all 300ms ease-out;
    transition-property: transform, box-shadow;
    box-shadow: 0px 1px 16px 0px rgba(0,0,0,0);
    /* padding-top: 80px; */
}
.RightDrawer_RightDrawer__56BrI.RightDrawer_Expand__12dz6 {
    transform: translate3d(0%, 0, 0);
    box-shadow: 0px 1px 16px 0px rgba(0,0,0,0.3);
}
.RightDrawer_CloseButton__2pWAq {
    margin-bottom: 10px;
    /* padding-left: 20px; */
    padding :20px;
    height: 60px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    background-color: var(--background-color);
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 150ms ease-out;
    box-shadow: 0px 1px 8px 0px var(--box-shadow-soft);

    display: flex;
    align-items: center;
}
.RightDrawer_CloseButton__2pWAq svg {
    color: var(--link-color);
    transition: all 150ms ease-out;
    transition-property: color, transform;
}
.RightDrawer_CloseButton__2pWAq:hover  svg{
    color: var(--link-hover-color);
    transform: translateX(20px);
}
.RightDrawer_Backdrop__3PK-T {
    position: fixed;
    z-index: 91;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background 300ms ease-out;
    pointer-events: none;
    transform: translate3d(0, 0, 0);
}
.RightDrawer_Backdrop__3PK-T.RightDrawer_Expand__12dz6 {
    background-color: rgba(0, 0, 0, 0.25);
    pointer-events: all;
}


@media (max-width:768px) {
    .RightDrawer_RightDrawer__56BrI {
        font-size: 0.8rem;
    }
}
.MaterialInput_Input__181bC {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    resize: none;
}


/* with icon */

.MaterialInput_LoginInputContainer__3bOU9 {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.MaterialInput_LoginIconContainer__2M8w8 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    flex-grow: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    /* background-color: red; */
    z-index: 1;
}
.MaterialInput_LoginIconContainer__2M8w8 svg {
    padding-right: 0;
    color: var(--placeholder-color);
}
.MaterialInput_LoginIconContainer__2M8w8 i {
    width: 20px;
    height: 20px;
    text-align: center;
    color: var(--placeholder-color);
}
.TenderSearch_SearchBar__1A-oA {
    min-height: 400px;
    box-sizing: border-box;
}
.TenderSearch_SubmitButtons__3s5fE {
    display: grid;
    grid-template-columns:  calc(100% - 3rem) 2.8rem;
    grid-gap: 3px;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
    position: relative;
    box-sizing: border-box;
    margin-top: calc(1rem - 8px);
}
.TenderSearch_ResetFormButton__1gjUP {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px !important;
    color: var(--button-text-color) !important;
    background-color: var(--button-background-color) !important;
    box-shadow: 0px 1px 14px 0px var(--box-shadow-soft);
    box-sizing: border-box;
}
.TenderSearch_ResetFormButton__1gjUP svg {
    transition: transform 150ms linear;
}
.TenderSearch_ResetFormButton__1gjUP:hover svg{
    transform: rotate(-180deg);
}
.TenderSearch_BookmarkSearch__1VI1V button {
    width: 100%;
    margin-top: 1rem;
    text-transform: none;
    font-weight: 600;
}
.TenderSearch_BookmarkSearch__1VI1V svg {
    padding-right: 5px;
}


@media (max-width:600px) {
    .TenderSearch_BookmarkSearch__1VI1V button {
        margin-top: 15px;
    }
    
    .TenderSearch_SearchBar__1A-oA {
        min-height: 200px;
    }
    .TenderSearch_SearchBar__1A-oA form>div {
        /* margin: 0 !important; */
    }
}
.BookmarkedTenderSearch_BookmarkedTenderSearch__1y2KO {
    height: 60vh;
    overflow: auto;
}
.BookmarkedTenderSearch_Item__1Bw7t {
    margin: 0.7rem;
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.12);
    cursor: pointer;
    transition: border 150ms linear;
    color: var(--text-color);
    background-color: var(--background-color);
    border: 1px solid transparent;
    border-radius: 10px;
}
.BookmarkedTenderSearch_Item__1Bw7t:hover {
    /* box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.3); */
    border: 1px solid #2ea0fd;
}
.BookmarkedTenderSearch_Label__31XXr {
    max-width: 70%;
    overflow: hidden;
}

.BookmarkedTenderSearch_Label__31XXr::first-letter {
    text-transform: capitalize;
}
.BookmarkedTenderSearch_Icon__eOLe2 {
    transition: all 150ms linear;
    cursor: pointer;
    padding: 5px;
}
.BookmarkedTenderSearch_Icon__eOLe2:hover{
    color: #fd2e2e;
}
.BookmarkedTenderSearch_Icon__eOLe2 svg {
    padding: 0;
}
.BookmarkedTenderSearch_NoItems__QchXO {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
}
.BookmarkedTenderSearch_NoItems__QchXO svg {
    color: #2ea0fd;
    margin-right: 5px;
}
@media (min-width: 480px) {
    .BookmarkedTenderSearch_Item__1Bw7t {
        padding: 1.4rem;
        margin: 1.4rem;
    }
}
.NewsSearch_NewsSearch__39qTj  {
    margin-top: 8px;
}
.NewsSearch_NewsSearch__39qTj button {
    margin-top: 16px;
    box-sizing: border-box;
}
.BlogSearch_BlogSearch__2N097  {
    margin-top: 8px;
}
.BlogSearch_BlogSearch__2N097 button {
    margin-top: 16px;
    box-sizing: border-box;
}
.SideBar_SideBar__int39 {
    padding: 0;
    margin: 0;
    position: fixed;
    top: 50px;
    left: 0;
    bottom: 0;
    width: 0;
    background-color: var(--background-color);
    box-shadow: 0 0 20px 0 var(--box-shadow-soft);
    z-index: 10;
    border-right: var(--border-navigation);
}
@media (min-width: 1270px) {
    .SideBar_SideBar__int39 {
        width: 280px;
    }
}
.Layout_Content__JhGc5 {
    position: relative;
    width: calc(100% - 280px);
    top: 60px;
    left:280px;
    box-sizing: border-box;
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
    scrollbar-width: thin;
    scrollbar-color: #141c281f rgba(255, 255, 255, 1);
    background:var(--content-background);
    min-height: calc(100vh - 50px);
    padding-bottom: 50px;
}
.Layout_Content__JhGc5.Layout_Fullwidth__24AhX {
    left:0;
    width: 100%;
}
@media (max-width: 1270px) {
    .Layout_Content__JhGc5 {
        left: 0;
        width: 100%;
    }
}
.Footer_Footer__2vf1h {
    background-color: #333;
    color: #fff;
    box-sizing: border-box;
    position: relative;
    margin-top: 20px;
}
.Footer_Header__26rqD {
    border-top: var(--footer-separator);
    
    border-bottom: var(--footer-separator);
    height: 60px;
    position: relative;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* padding: 10px; */
}
.Footer_Logo__1-tgT {
    display: flex;
    justify-content: center;
    align-items: center;
	height: 100%;
	/* width: 100%; */
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}
.Footer_Social__1EB7l {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-left: var(--footer-separator);
    padding-left: 20px;
}
.Footer_Social__1EB7l a {
    margin: 0 10px;
    color: rgb(201, 201, 201);
    transition: color 150ms ease-out;
}
.Footer_Social__1EB7l a:hover {
    color: #fff;
}

.Footer_NightLogoImage__244sz {
    display: block;
	height: 70% !important;
	height: 100%;
    width: auto;
	/* filter: var(--drop-shadow-logo); */
}
.Footer_FooterItemContainer__1eADG {
    /* background-color: red; */
    /* box-sizing: border-box; */
    padding: 30px 10%;
    box-sizing: border-box;
}
.Footer_FooterItem__2O2Ki {
    display: flex;
    flex-flow: column;
    /* border: var(--border-separator); */
    padding: 10px;
    box-sizing: border-box;
}
.Footer_FooterItem__2O2Ki>div {
    color: rgb(201, 201, 201);
}
.Footer_Content__lQyh_ {
    box-sizing: border-box;
}
.Footer_Content__lQyh_ h3 {
    display: flex;
    align-items: center;
}
.Footer_Content__lQyh_ h3>span {
    margin-right: 10px;
}
.Footer_Content__lQyh_ a {
    color: rgb(201, 201, 201);
    margin-bottom: 10px;
    transition: color 150ms ease-out;
}
.Footer_Content__lQyh_ a:hover {
    color: #fff;
}
.Footer_End__3ozFu {
    box-sizing: border-box;
    text-align: center;
    padding: 20px;
    border-top: var(--footer-separator);
    color: rgb(201, 201, 201);
}
.Footer_End__3ozFu>span {
    color: rgb(255, 255, 255);
}
.Footer_Newsletter__1rgiB {
    display: flex;
    margin-top: 20px;
    width: 100%;
}

.Footer_Newsletter__1rgiB input, .Footer_Newsletter__1rgiB button {
    flex-wrap: wrap;
    align-items: flex-start;
}

.Footer_Newsletter__1rgiB button {
    background-color: var(--button-background-color);
    border: none;
    border-radius: 4px;
    height: 48px;
    min-width: 48px;
    max-width: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 1rem;
    color: #fff;
    margin-left: 10px;
    transition: all 150ms ease-out;
    transition-property: color, background-color;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
}
.Footer_Newsletter__1rgiB button:hover {
    background-color: #fff;
    color:#333;
}
.Footer_Newsletter__1rgiB button svg {
    width: 100%;
    line-height: 48px;
}
.Footer_Newsletter__1rgiB input {
    height: 48px;
    line-height: 48px;
    padding: 0 1rem;
    outline: none;
    font-size: 1rem;
    margin: 0 0 16px 0;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: block;
    font-weight: 500;
    opacity: 1;
    border-radius: 4px;
    border: none;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
    background: #262626;
    margin-bottom: 0;
    color: rgb(201, 201, 201);
}
.VerificationWarning_VerificationWarning__3_ymF {
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    background-color: var(--link-color);
    color: var(--background-color);
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    opacity: 0.9;
}
.VerificationWarning_Content__1gH0B svg {
    color:  #ff4154;
    padding-right: 5px;
    margin-bottom: -3px;
}
.VerificationWarning_Content__1gH0B a {
    margin-left: 5px;
    color:  #ff4154;
	-webkit-filter: drop-shadow(1px 1px 1px var(--title-color));
	        filter: drop-shadow(1px 1px 1px var(--title-color));
}
.VerificationWarning_CloseIcon__vLmYg {
    color: var(--background-color);
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
}
.PageTitle_PageTitle__2ICpE {
    width: 100%;
    margin-bottom: 30px;
    background-image: url(../../static/media/hero.ad04c5fc.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-sizing: border-box;
}
.PageTitle_PageTitle__2ICpE h2 {
    color: var(--title-color);
	/* filter: var(--drop-shadow); */
    margin: 0;
}

.PageTitle_PageTitleOverlay__1BkTv {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    background:#fff;
    background:var(--pagetitle-overlay-background);
    box-shadow: inset 0px 1px 10px 0px var(--box-shadow-soft);
}

@media (min-width:500px) {
    .PageTitle_PageTitle__2ICpE h2 {
        padding: 1rem;
    }
}

@media (min-width:600px) {
    .PageTitle_PageTitleOverlay__1BkTv {
        justify-content: space-between;
        padding: 3rem;
        flex-flow: unset;
    }
}
.BreadCrumbs_BreadCrumbs__1WgxS {
    max-width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    list-style-type: none;
    padding: 10px;
    flex-wrap: wrap;
    margin: 1rem 2rem;
    box-shadow: 0 3px 10px var(--box-shadow-medium);
}
.BreadCrumbs_BreadCrumb__2i8Dl {
    padding: 0 5px;
    white-space: nowrap;
}
.BreadCrumbs_BreadCrumb__2i8Dl a{
    color: #fff;
    text-decoration: none;
}

.BreadCrumbs_Child__31EA9::before {
    content: "";
    display: inline-block;
    height: 0;
    width: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #aaa;
    font-size: 14px;
    margin-right: 12px;
}
.Login_Login__13DUD {
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.Login_FormHolder__3aZe2 {
    width: 100%;
    margin: 20px auto;
}
.Login_Action__uYxVh {
    color: var(--text-color);
}
.Login_Login__13DUD h2{
    color: var(--title-color);
    transform: scale(1.1);
    margin-bottom: 5px;
}
.Login_FormHolder__3aZe2 button {
    margin-top: 10px;
}
.Login_Action__uYxVh a {
    color: var(--link-hover-color);
    text-decoration: none;
    transition: all 150ms linear;
}
.Login_Action__uYxVh a:hover {
    color: var(--title-color);
}

@media(min-width:500px) {
    .Login_FormHolder__3aZe2 {
        max-width: 500px;
    }
    .Login_Login__13DUD {
        max-width: 80%;
    }
}
.Register_Register__3UcLk {
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    /* padding-bottom: 120px; */
}

.Register_FormHolder__1anvo {
    width: 100%;
    margin: 20px auto;
}
.Register_FormHolder__1anvo button {
    margin-top: 10px;
}
.Register_Action__ESUb9 {
    color: var(--text-color);
}
.Register_Register__3UcLk h2{
    color: var(--title-color);
    transform: scale(1.1);
    margin-bottom: 5px;
}
.Register_Action__ESUb9 a {
    color: var(--link-hover-color);
    text-decoration: none;
    transition: all 150ms linear;
}
.Register_Action__ESUb9 a:hover {
    color: var(--title-color);
}

@media(min-width:500px) {
    .Register_FormHolder__1anvo {
        max-width: 500px;
    }
    .Register_Register__3UcLk {
        max-width: 80%;
    }
}
