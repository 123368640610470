@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800&subset=latin-ext');

	/* Main Font */
	body, h1, h2, h3, h4, h5, h6,  input[type="text"], input[type="password"], input[type="email"],
	textarea, select, input[type="button"], input[type="submit"], button
	{ font-family: "Nunito", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important; text-transform: none; }

html{
  font-size: 14px;
  padding: 0;
  margin: 0;
  background-color: var(--background-color);
}

@media only screen and (min-width: 480px) and (max-width: 767px){ 
  html{
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){ 
  html{
    font-size: 15px;
    background-color: var(--background-color);
  }
}

@media only screen and (min-width: 1270px){ 
  html{
    font-size: 16px;
  }
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}
.no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--title-color);
}
.no-results svg {
  color: var(--info-color);
  padding-right: 10px;
}
body {
  background-color: var(--background-color);
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  transition: all 150ms linear;

  /* COLORS */
  --background-color: #fff;
  --success-color:#2ea0fd;
  --text-color: rgb(100, 100, 100);
  --text-shadow-glow: rgb(255, 255, 255, 1);
  --drop-shadow: drop-shadow(2px 2px 2px #000000c4);
  --drop-shadow-logo: drop-shadow(2px 2px 2px transparent);
  --box-shadow-softest: rgba(0,0,0,0.06);
  --box-shadow-soft: rgba(0,0,0,0.12);
  --box-shadow-medium: rgba(0,0,0,0.22);
  --box-shadow-hard: rgba(0,0,0,0.32);
  --button-box-shadow: #8007131a;
  --tender-info-background: linear-gradient(to left, rgba(0, 0, 0, 0.06) 0%, rgba(255,255,255,0));
  --tender-info-background-hover: linear-gradient(to left,rgba(54, 189, 120, 0.13) 0%, rgba(255,255,255,0));
  --contact-card-title-background: linear-gradient(to left, rgba(0, 0, 0, 0.06) 0%, rgba(255,255,255,0));
  --tender-document-background: rgba(233, 233, 233, 0.644);
  --tender-document-background-hover: #2a41e8;
  --content-background:  #f0f2f5;
  --content-background:  #fafafa;
  --pagetitle-overlay-background: linear-gradient(
    to right, 
    #fafafa 10%, 
    #fafafaea 50%,
    #fafafad5  90%
  );
  --header-overlay-background: linear-gradient(
    to right, 
    #fafafa 10%, 
    #fafafa8f 50%,
    #fafafa3b  90%
  );
  --link-color: rgb(119, 119, 119);
  --link-hover-color: #2a41e8;
  --link-hover-color:#800713;
  /* --link-hover-bg-color: #2a40e810; */
  --link-hover-bg-color: #e4e8ec;
  --link-hover-bg-color: rgb(245, 245, 245);
  --info-color: #2ea0fd;
  --info-border-color: #2ea0fdb2;
  --submenu-arrow-color: #a8a8a8;
  --submenu-arrow-bg-color: #f0f0f0;
  --submenu-arrow-hover-color:rgba(232, 42, 42, 0.295)8;
  --submenu-arrow-hover-bg-color: #8f8f8f65;
  --footer-separator: 1px solid #525252;
  --border-separator: 1px solid #e0e0e0;
  --border-separator-color: #e0e0e0;
  --border-navigation: 1px solid transparent;
  --button-background-color: #2a41e8;
  --button-background-color: #333;
  --button-text-color: #fff;
  --toggle-button-background-color: #2a41e8;
  --toggle-button-background-color: #800713;
  --toggle-button-text-color: #fff;
  --button-disabled-background-color: #757575;
  --tooltip-background-color: #333;
  --tooltip-color: #fff;
  --placeholder-color: #8d8d8d;
  --label-color: rgb(155, 155, 155);
  --select-groupName-color: #fff;
  --select-groupName-hover-color: rgba(160, 160, 160, 0.199);
  --title-color: rgb(51, 51, 51);
  --location-color: #6e6e6e;
  --tag-color: rgb(105, 105, 105);
  --toggle-btn-bg-selected: rgba(54, 118, 170, 0.432);
  --toggle-btn-selected: #fff;
  --notification-bg-color: #444;
  --notification-text-color: #fff;
  --notification-border: 2px solid #444;
  --option-hover-bg-color: #e4e8ec;
  --search-input-background-color: rgb(243, 243, 243);
  --input-text-color: rgb(122, 122, 122);
  --input-border-color: rgba(0, 0, 0, 0.23);
  --detail-item-background:linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1));
  --news-card-content-bg: rgba(255, 255, 255, 0.9);
}
body.night {
  /* COLORS */
  --background-color: #424242;
  --success-color: rgba(255, 255, 255, 0.774);
  --text-color: rgb(206, 206, 206);
  --text-shadow-glow: transparent;
  --drop-shadow: drop-shadow(2px 2px 2px transparent);
  /* --drop-shadow-logo: drop-shadow(1px 1px 1px #fff); */
  --box-shadow-softest: rgba(255, 255, 255, 0);
  --box-shadow-soft: rgba(255, 255, 255, 0);
  --box-shadow-medium: rgba(255,255,255,0);
  --box-shadow-hard: rgba(255,255,255,0);
  --button-box-shadow: #ffffff0a;
  --tender-info-background: linear-gradient(to left, rgba(0, 0, 0, 0.06) 0%, rgba(255,255,255,0));
  --tender-info-background-hover: linear-gradient(to left,rgba(54, 189, 120, 0.13) 0%, rgba(255,255,255,0));
  --contact-card-title-background: linear-gradient(to left, rgba(0, 0, 0, 0.06) 0%, rgba(255,255,255,0));
  --tender-document-background: rgba(233, 233, 233, 0.644);
  --tender-document-background-hover: #2a41e8;
  /* --content-background:  #f0f2f5; */
  --content-background:  #303030;
  --pagetitle-overlay-background: linear-gradient(
    to right, 
    #303030 10%, 
    #303030a2 50%,
    #30303048  90%
  );
  --header-overlay-background: linear-gradient(
    to right, 
    #303030 10%, 
    #3030308f 50%,
    #3030303b  90%
  );
  --link-color: rgb(240, 240, 240);
  --link-hover-color:#2ea0fd;
  --link-hover-color: #ff4154;
  /* --link-hover-bg-color: #2a40e810; */
  --link-hover-bg-color: #64b5f6;
  --link-hover-bg-color: rgb(88, 88, 88);
  --info-color: #2ea0fd;
  --info-border-color: #2ea0fdb2;
  --submenu-arrow-color: rgb(211, 211, 211);
  --submenu-arrow-bg-color: #97979765;
  --submenu-arrow-hover-color:rgb(255, 255, 255);
  --submenu-arrow-hover-bg-color: #818181ee;
  --footer-separator: 1px solid#6d6d6d;
  --border-separator: 1px solid#ffffff65;
  --border-separator-color:#ffffff65;
  --border-navigation: 1px solid  #6d6d6d;
  --button-background-color: rgb(32, 32, 32);
  --button-text-color: #fff;
  --toggle-button-background-color: #ff4154;
  --toggle-button-text-color: rgb(233, 233, 233);
  --button-disabled-background-color: #757575;
  --tooltip-background-color:rgb(0, 0, 0);
  --tooltip-color: #fff;
  --placeholder-color: #8d8d8d;
  --label-color: rgb(155, 155, 155);
  --select-groupName-color: #fff;
  --select-groupName-hover-color: rgba(160, 160, 160, 0.199);
  --title-color: rgb(218, 218, 218);
  --location-color:  #9e9e9e;
  --tag-color:  #4b6774;
  --toggle-btn-bg-selected: rgba(54, 118, 170, 0.432);
  --toggle-btn-selected: #fff;
  --notification-bg-color: rgb(126, 126, 126);
  --notification-text-color: rgb(255, 255, 255);
  --notification-border: 2px solid #444;
  --option-hover-bg-color: #e4e8ec;
  --search-input-background-color: rgb(243, 243, 243);
  --input-text-color: rgb(122, 122, 122);
  --input-border-color: rgba(0, 0, 0, 0.23);
  --detail-item-background:linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1));
  --news-card-content-bg: rgba(0, 0, 0, 0.85);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* inputs */
::placeholder {
  color: var(--placeholder-color);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* MUI styles */

/* outlined inputs */
fieldset {
  transition : all 150ms ease-in-out !important;
}
.MuiOutlinedInput-root.Mui-focused fieldset,
.MuiOutlinedInput-root.Mui-focused:hover fieldset {
  border-width: 1px !important;
  border-color: var(--link-color) !important;
}
.MuiOutlinedInput-root fieldset {
  border-width: 1px !important;
  border: var(--border-separator) !important;
}
.MuiOutlinedInput-root:hover fieldset {
  border-color: var(--link-color) !important;
}
.MuiOutlinedInput-root {
  color:  var(--text-color) !important;
}
.MuiOutlinedInput-root.Mui-error fieldset,
.MuiOutlinedInput-root.Mui-error:hover fieldset {
  border-width: 1px !important;
  border-color: red !important;
}
/* underlined inputs - textfields */
.MuiInput-underline input {
  color: var(--title-color)
}
.MuiInput-underline::before {
  border-bottom-color: var(--border-separator-color) !important;
}
.MuiInput-underline::after {
  border-bottom-color: var(--link-hover-color) !important;
}
/* triangle select-icon */
.MuiOutlinedInput-root  .MuiIconButton-root {
  color : unset !important;
}
/* labels */
label {
  transition: color 600ms ease-out;
}
label.MuiInputLabel-root{
  color: var(--link-color) !important;
  color: var(--label-color) !important;
}
label.Mui-focused{
  color: var(--link-color) !important;
}

label.Mui-error{
  color: red !important;
}
/* date input */
.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected{
  background-color: var(--link-hover-color) !important;
}
.MuiDialogActions-root span {
  color:var(--link-hover-color) !important;
}


/* select - options container */
.MuiAutocomplete-paper {
  border: 1px solid #3f51b5;
  border: 1px solid var(--link-color);
  background-color: red;
}
.MuiAutocomplete-popper>div{
  background-color: var(--background-color) !important;
}

/* options */
.MuiAutocomplete-option {
  padding: 15px !important;
  background-color:var(--background-color);
  color:  var(--link-color) 
}
.MuiAutocomplete-option[data-focus="true"] {
  color: var(--link-hover-color);
} 
.MuiAutocomplete-option:hover {
  color: var(--link-hover-color);
}
.MuiAutocomplete-groupLabel {
  background-color: var(--link-hover-bg-color) !important;
  color: var(--link-hover-color) !important;
  border-bottom: var(--border-separator);
}
.MuiAutocomplete-noOptions {
  color: var(--link-color) !important;
}

/* outline buttons */
.MuiButton-outlinedPrimary {
  color: var(--link-color) !important;
  border: 1px solid var(--link-color) !important;
  transition: all 150ms ease-out !important;
  transition-property: border, color;
}
.MuiButton-outlinedPrimary:disabled {
  color: rgb(114, 114, 114) !important;
  border: 1px solid rgb(114, 114, 114) !important;
}

.myMuiConfirm {
  color: var(--success-color) !important;
  border: 1px solid var(--success-color) !important;
  transition: all 150ms ease-out !important;
  transition-property: border, color;
}
.myMuiConfirm:disabled {
  color: rgb(114, 114, 114) !important;
  border: 1px solid rgb(114, 114, 114) !important;
}

.myMuiButton {
  color: var(--title-color) !important;
  border: 1px solid var(--title-color) !important;
  transition: all 150ms ease-out !important;
  transition-property: border, color;
}
.myMuiButton:disabled {
  color: rgb(114, 114, 114) !important;
  border: 1px solid rgb(114, 114, 114) !important;
}
/* .MuiTextField-root {
  color: var(--link-hover-color) !important;
  border-color: var(--link-hover-color) !important;
} */

/* pagination styles */
/* .MuiPagination-ul li{
  color: var(--title-color) !important;
} */
button.MuiPaginationItem-root.Mui-selected {
  color: #fff;
  background-color: var(--button-background-color);
}
button.MuiPaginationItem-root:hover {
  color: #fff;
  background-color: var(--button-background-color) !important;
}
button.MuiPaginationItem-root {
  color: var(--title-color);
}
.MuiPaginationItem-ellipsis {
  color: var(--title-color) !important;
}
/* switch button */
.MuiSwitch-colorPrimary.Mui-checked {
  color: var(--button-background-color) !important;
}
/* ******************************* end MUI ***************************************** */

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(143, 55, 55, 0);
  background: var(--background-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #141c2818; 
  background: var(--link-color); 
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #141c283d;  */
  background: var(--link-hover-color);
}
/* ******** */

/* non selectable link text */
a {
  -webkit-touch-callout : none;     /* iOS Safari */
  -webkit-user-select   : none;     /* Chrome/Safari/Opera */
  -khtml-user-select    : none;     /* Konqueror */
  -moz-user-select      : none;     /* Firefox */
  -ms-user-select       : none; 
  
  text-decoration: none !important;
  
  user-select: none !important;
  -webkit-tap-highlight-color: transparent !important;

}
div, h1, h2, h3, h4, span, p , button{
  -webkit-tap-highlight-color: transparent !important;
}
/* svg, span.material-icons {
  padding-right: 15px;
} */

/* PAGINATION */
.pagination {
  background-color: var(--background-color);
  position: fixed;
  bottom: 0;
  left: 50%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, 150%);
  transition: transform 150ms ease-out;
  border-radius: 4px 4px 0 0;
  z-index: 10;
  border: var(--border-separator);
  border-bottom: none;
}
.pagination.show {
  transform: translate(-50%, 0);
}

/* RIPPLE EFFECT */
.ripple {
  position:relative;
  overflow: hidden;
  transform:translate(0, 0, 0);
}
.ripple::after {
  content:'';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple:active::after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

/* TOOLTIPS */
.tooltipLeft {
  position: relative;
}
.tooltipLeft::after{            
  content: attr(data-tooltip);
  font-size: 0.8rem;
  display: block;
  position: absolute;
  top: 50%;
  right: calc(90% + 11px);
  transform: translateY(-50%);
  background-color: var(--tooltip-background-color);
  color: var(--tooltip-color);
  padding: 0.5rem;
  border-radius: 4px;
  opacity: 0;
  transition: all 150ms ease-in;
  width: fit-content;
  pointer-events: none;
  text-align: center;
  white-space:nowrap;
}
.tooltipLeft:hover::after{
  right: calc(100% + 11px);
  opacity: 1;
  z-index: 50;
}

.tooltipLeft::before {
  content: '';
  position: absolute;
  border: 6px solid #333;
  border-color: transparent transparent transparent var(--tooltip-background-color);
  top: 50%;
  right: 90%;
  transform: translateY(-50%);
  box-sizing: border-box;
  opacity: 0;
  transition: all 150ms ease-in;
  padding: 0;
  pointer-events: none;
}
.tooltipLeft:hover::before{
  right: 100%;
  opacity: 1;
  z-index: 50;
}
/* tooltip bottom-left */
.tooltipBottomLeft {
  position: relative;
}
.tooltipBottomLeft::after{            
  content: attr(data-tooltip);
  font-size: 0.8rem;
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  /* transform: translateX(-50%); */
  background-color: var(--tooltip-background-color);
  color: var(--tooltip-color); 
  padding: 0.5rem;
  border-radius: 4px;
  opacity: 0;
  transition: all 150ms ease-in;
  width: fit-content;
  pointer-events: none;
  text-align: center;
  white-space:nowrap;
  z-index: 500;
}
.tooltipBottomLeft:hover::after{
  top: calc(100% + 12px);
  opacity: 1;
  /* z-index: 50; */
}

.tooltipBottomLeft::before {
  content: '';
  position: absolute;
  border: 6px solid #333;
  border-color: transparent transparent var(--tooltip-background-color) transparent;
  top: calc(100% - 12px);
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  opacity: 0;
  transition: all 150ms ease-in;
  padding: 0;
  pointer-events: none;
}
.tooltipBottomLeft:hover::before{
  top: calc(100%);
  opacity: 1;
  z-index: 50;
}

.tooltipRight {
  position: relative;
}
.tooltipRight::after{            
  content: attr(data-tooltip);
  font-size: 0.8rem;
  display: block;
  position: absolute;
  top: 50%;
  left: calc(90% + 12px);
  transform: translateY(-50%);
  background-color: var(--tooltip-background-color);
  color: var(--tooltip-color);
  padding: 0.5rem;
  border-radius: 4px;
  opacity: 0;
  transition: all 150ms ease-in;
  width: fit-content;
  pointer-events: none;
  text-align: center;
  white-space:nowrap;
  z-index: 500;
}
.tooltipRight:hover::after{
  left: calc(100% + 12px);
  opacity: 1;
  /* z-index: 50; */
}

.tooltipRight::before {
  content: '';
  position: absolute;
  border: 6px solid #333;
  border-color: transparent var(--tooltip-background-color) transparent transparent ;
  top: 50%;
  left: 90%;
  transform: translateY(-50%);
  box-sizing: border-box;
  opacity: 0;
  transition: all 150ms ease-in;
  padding: 0;
  pointer-events: none;
}
.tooltipRight:hover::before{
  left: 100%;
  opacity: 1;
  z-index: 50;
}

.tooltipBottom {
  position: relative;
}
.tooltipBottom::after{            
  content: attr(data-tooltip);
  font-size: 0.8rem;
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--tooltip-color);
  color: var(--tooltip-background-color); 
  padding: 0.5rem;
  border-radius: 4px;
  opacity: 0;
  transition: all 150ms ease-in;
  width: fit-content;
  pointer-events: none;
  text-align: center;
  white-space:nowrap;
  z-index: 500;
}
.tooltipBottom:hover::after{
  top: calc(100% + 12px);
  opacity: 1;
  /* z-index: 50; */
}

.tooltipBottom::before {
  content: '';
  position: absolute;
  border: 6px solid #333;
  border-color: transparent transparent var(--tooltip-color) transparent;
  top: calc(100% - 12px);
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  opacity: 0;
  transition: all 150ms ease-in;
  padding: 0;
  pointer-events: none;
}
.tooltipBottom:hover::before{
  top: calc(100%);
  opacity: 1;
  z-index: 50;
}


/* after element on hover */
.hover-underline {
  position: relative;
  width: fit-content;
}
.hover-underline::after {
  position: absolute;
  content: '';
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ff4154;
  /* transition: width 150ms ease-out; */
  transition: transform 300ms ease-out;
  transform: scaleX(0);
  transform-origin: right;
}
.hover-underline:hover::after {
  /* width: 100%; */
  transform: scaleX(1);
  transform-origin: left;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all 500ms ease;
}


.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
/* .menu-secondary-exit {

} */
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all 500ms ease;
}
.menu {
  width: 100%;
}

@media (max-width:600px) {
	.tooltipRight::after,
	.tooltipRight::before,
	.tooltipLeft::after,
	.tooltipLeft::before {
    display: none;
  }
}
.backdrop {
  opacity: 0;
  background-color: black;
}

/* animations */

.fadeIn {
  animation: fadeIn 300ms ease-in;
}
@keyframes fadeIn {
  0%{opacity:0}
  100%{opacity:1;}
}

.scaleIn {
  animation: scaleIn 300ms ease-out;
}
@keyframes scaleIn {
  0%{transform:scale(0.97);}
  50%{transform:scale(1.01);}
  100%{transform:scale(1);}
  
  /* 0%{
    transform:translateY(-30px);
    opacity:0;
  }
  50%{
    transform:translateY(10px);
  }
  100%{
    transform:translateY(0);
    opacity:1;
  } */
}

.paginationScaleIn {
  animation: paginationScaleIn 300ms ease-out;
}
@keyframes paginationScaleIn {
  0%{transform: scale(0.97) translate(-50%);}
  50%{transform: scale(1.03) translate(-50%);}
  100%{transform: scale(1) translate(-50%);}
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid green; */
  -webkit-text-fill-color: var(--text-color);
  /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
  transition: background-color 5000s ease-in-out 0s;
}