.Logo {
    display: flex;
    justify-content: center;
    align-items: center;
	height: 100%;
	width: 100%;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    
    /* height: 500px; */
    /* background-color: #000; */

    /* background-position: center;
    background-size: contain;
    background-repeat: no-repeat; */
	/* filter: var(--drop-shadow-logo); */
}
.LogoImage {
    display: block;
	height: 100%;
	width: auto;
	/* filter: var(--drop-shadow-logo); */
    object-fit:cover;
    object-position:50% 50%;
}
.NightLogoImage {
    display: block;
	height: 100%;
    width: auto;
    object-fit:cover;
    object-position:50% 50%;
	/* filter: var(--drop-shadow-logo); */
}