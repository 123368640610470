.Footer {
    background-color: #333;
    color: #fff;
    box-sizing: border-box;
    position: relative;
    margin-top: 20px;
}
.Header {
    border-top: var(--footer-separator);
    
    border-bottom: var(--footer-separator);
    height: 60px;
    position: relative;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* padding: 10px; */
}
.Logo {
    display: flex;
    justify-content: center;
    align-items: center;
	height: 100%;
	/* width: 100%; */
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}
.Social {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-left: var(--footer-separator);
    padding-left: 20px;
}
.Social a {
    margin: 0 10px;
    color: rgb(201, 201, 201);
    transition: color 150ms ease-out;
}
.Social a:hover {
    color: #fff;
}

.NightLogoImage {
    display: block;
	height: 70% !important;
	height: 100%;
    width: auto;
	/* filter: var(--drop-shadow-logo); */
}
.FooterItemContainer {
    /* background-color: red; */
    /* box-sizing: border-box; */
    padding: 30px 10%;
    box-sizing: border-box;
}
.FooterItem {
    display: flex;
    flex-flow: column;
    /* border: var(--border-separator); */
    padding: 10px;
    box-sizing: border-box;
}
.FooterItem>div {
    color: rgb(201, 201, 201);
}
.Content {
    box-sizing: border-box;
}
.Content h3 {
    display: flex;
    align-items: center;
}
.Content h3>span {
    margin-right: 10px;
}
.Content a {
    color: rgb(201, 201, 201);
    margin-bottom: 10px;
    transition: color 150ms ease-out;
}
.Content a:hover {
    color: #fff;
}
.End {
    box-sizing: border-box;
    text-align: center;
    padding: 20px;
    border-top: var(--footer-separator);
    color: rgb(201, 201, 201);
}
.End>span {
    color: rgb(255, 255, 255);
}
.Newsletter {
    display: flex;
    margin-top: 20px;
    width: 100%;
}

.Newsletter input, .Newsletter button {
    flex-wrap: wrap;
    align-items: flex-start;
}

.Newsletter button {
    background-color: var(--button-background-color);
    border: none;
    border-radius: 4px;
    height: 48px;
    min-width: 48px;
    max-width: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 1rem;
    color: #fff;
    margin-left: 10px;
    transition: all 150ms ease-out;
    transition-property: color, background-color;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
}
.Newsletter button:hover {
    background-color: #fff;
    color:#333;
}
.Newsletter button svg {
    width: 100%;
    line-height: 48px;
}
.Newsletter input {
    height: 48px;
    line-height: 48px;
    padding: 0 1rem;
    outline: none;
    font-size: 1rem;
    margin: 0 0 16px 0;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: block;
    font-weight: 500;
    opacity: 1;
    border-radius: 4px;
    border: none;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
    background: #262626;
    margin-bottom: 0;
    color: rgb(201, 201, 201);
}